import Div from '../Div'
import CloseButton from '../buttons/CloseButton'
import UI from '../UI'
import {isEmpty, isUndefined} from '../../../../utils/GeneralUtils'

export const POSITION_BOTTOM_RIGHT = 'bottomRight'
export const POSITION_TOP_RIGHT = 'topRight'

function Overlay({
  id, onClose, content = null, position = null, header = null
}) {
  function getHeaderUI() {
    const headerOuterContainer = Div({classNames: 'a1mv__overlay-header'})

    if (!isEmpty(header)) {
      const headerContainer = Div({classNames: 'a1mv__overlay-window-header'})
      UI.add(headerOuterContainer, headerContainer)
      UI.add(headerContainer, header)
    }

    return headerOuterContainer
  }

  function getFooterUI() {
    const footerContainer = Div({classNames: 'a1mv__overlay-footer'})

    return footerContainer
  }

  function getUI() {
    // main container
    let classNames = 'a1mv__overlay-window'
    if (position === POSITION_BOTTOM_RIGHT) {
      classNames += ' a1mv__bottom-right'
    } else if (position === POSITION_TOP_RIGHT) {
      classNames += ' top-right'
    }
    const mainContainer = Div({id, classNames})

    // wrapper
    const wrapperContainer = Div({classNames: 'a1mv__overlay-wrapper'})
    UI.add(mainContainer, wrapperContainer)

    // close button
    const closeButton = CloseButton({
      onClick: () => {
        if (!isUndefined(onClose)) {
          onClose()
        }
        UI.removeSelf(mainContainer)
      }
    })
    UI.add(mainContainer, closeButton)

    // header
    UI.add(wrapperContainer, getHeaderUI())

    // content
    const contentContainer = Div({classNames: 'a1mv__overlay-content'})
    if (!isEmpty(content)) {
      UI.add(contentContainer, content)
    }
    UI.add(wrapperContainer, contentContainer)

    // footer
    UI.add(wrapperContainer, getFooterUI())

    return mainContainer
  }

  return getUI()
}

export default Overlay
