import Button, {setDisabled} from '../../base/Button'
import Div from '../../base/Div'
import InlineIcon from '../../base/InlineIcon'
import Overlay, {POSITION_BOTTOM_RIGHT} from '../../base/overlay/Overlay'
import UI from '../../base/UI'
import {isEmpty, log} from '../../../../utils/GeneralUtils'
import LegendIconUI from './LegendIconUI'
import Intl from '../../../../intl/Intl'

function LegendUI({legendData = null, onClose}) {
  let contentContainer = null
  const legendIconsUIMap = {}
  let currentIconsUI = null
  const categoryButtons = []
  // const legendSites = null
  // const legendAmenities = null
  // let showingSites = true

  // function showSites() {
  //   if (!showingSites && !isEmpty(legendSites)) {
  //     UI.removeSelf(legendAmenities)
  //     UI.add(contentContainer, legendSites)
  //     showingSites = true
  //   }
  // }

  // function showAmenities() {
  //   if (showingSites && !isEmpty(legendAmenities)) {
  //     UI.removeSelf(legendSites)
  //     UI.add(contentContainer, legendAmenities)
  //     showingSites = false
  //   }
  // }

  // function getSitesButton() {
  //   const perspectiveButton = Button({
  //     id: 'perspective-button',
  //     classNames: 'a1mv__map-button a1mv__icon-perspective',
  //     onClick: () => {
  //       showSites()
  //     }
  //   })

  //   return perspectiveButton
  // }

  // function getAmenitiesButton() {
  //   const mapStyleButton = Button({
  //     id: 'map-style-button',
  //     classNames: 'a1mv__map-button a1mv__icon-map-style',
  //     onClick: () => {
  //       showAmenities()
  //     }
  //   })

  //   return mapStyleButton
  // }

  function showLegendIconUI(categoryId) {
    log('showLegendIconUI', legendIconsUIMap)
    const requestedLegendIconUI = legendIconsUIMap[categoryId]
    if (!isEmpty(currentIconsUI)) {
      UI.removeSelf(currentIconsUI)
    }
    log('showLegendIconUI', contentContainer)
    log('showLegendIconUI1', requestedLegendIconUI)
    UI.add(contentContainer, requestedLegendIconUI)
    currentIconsUI = requestedLegendIconUI
  }

  function getHeaderUI() {
    const headerContainer = Div({classNames: 'a1mv__head1'})

    const buttonsOuterContainer = Div({classNames: 'a1mv__clearfix'})
    const buttonsContainer = Div({classNames: 'a1mv__map-action-header-row'})
    UI.add(headerContainer, buttonsOuterContainer)
    UI.add(buttonsOuterContainer, buttonsContainer)

    if (legendData.categories.length > 0) {
      let catCtr = 0
      const activeClass = 'a1mv__action-active'
      legendData.categories.forEach((category) => {
        const classNames = `a1mv__action-icon-button ${catCtr === 0 ? activeClass : ''}`
        const icon = InlineIcon({
          classNames: `legend-action-btn-icon-${category.id}`
        })
        const label = Intl.simpleTranslate(`legendCategoryBtnLbl-${category.id}`, category.id)
        const legendIconUISelectorBtn = Button({
          id: `category-button-${category.id}`,
          ariaLabel: label,
          classNames,
          icon,
          label,
          disabled: catCtr === 0,
          onClick: (event) => {
            categoryButtons.forEach((button) => {
              setDisabled(button, false, true, activeClass)
            })
            setDisabled(event.currentTarget, true, true, activeClass)
            showLegendIconUI(category.id)
          }
        })
        categoryButtons.push(legendIconUISelectorBtn)
        UI.add(buttonsContainer, legendIconUISelectorBtn)
        catCtr += 1
      })
    }

    return headerContainer
  }

  function getContentUI() {
    contentContainer = Div({classNames: 'a1mv__pane-container'})

    let ctr = 0
    legendData.categories.forEach((category) => {
      const legendIconUI = LegendIconUI({category})

      legendIconsUIMap[category.id] = legendIconUI

      ctr += 1
      if (ctr === 1) {
        UI.add(contentContainer, legendIconUI)
        currentIconsUI = legendIconUI
      }
    })

    return contentContainer
  }

  function createLegendUI() {
    const overlay = Overlay({
      id: 'legend-overlay', position: POSITION_BOTTOM_RIGHT, header: getHeaderUI(), content: getContentUI(), onClose
    })

    const mainContainer = Div({})
    UI.add(overlay, mainContainer)

    return overlay
  }

  if (isEmpty(legendData)) {
    return null
  }

  return createLegendUI()
}

export default LegendUI
