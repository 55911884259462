/* eslint-disable max-len */
import { isEmpty, hackFixJSON } from "../../../../utils/GeneralUtils";
import { ACTION_TYPE, getActionObj } from "../../../../utils/ActionsUtils";
import PopupMainContainer from "../PopupMainContainer";
import UI from "../../base/UI";
import Div from "../../base/Div";
import Image from "../../base/Image";
import Anchor from "../../base/Anchor";
import Button from "../../base/Button";
import PopupActionButton from "../PopupActionButton";

function PopupCUIFacility(
  feature,
  callback,
  mainStyle = "",
  isHighlight = false
) {
  // TODO - not sure why the uiData gets stringified when passed in - fix.
  console.log(feature.properties.uiData);
  hackFixJSON(feature.properties);
  const { uiData } = feature.properties;


  function getFullUI() {
    const container = Div({ classNames: "cui_mapviewer_popup_container" });
    const contentContainer = Div({ classNames: "cui_mv_content_container" });
    const actionBarContainer = Div({
      classNames: "cui_mv_actionbar_container",
    });
    const imgContainer = Div({ classNames: "cui_mv_main_image_container" });
    const mainImage = Image({
      id: uiData.facImage.id,
      classNames: "cui_mv_main_image",
      src: uiData.facImage.source,
      alt: "Main Image"
    });
    const facilitySummary = Div({ classNames: "cui_mv_facility_summary" });
    const facilityTitleLink = Anchor({
      id: uiData.title.id,
      content: uiData.title.text,
      classNames: "cui_mv_facility_title",
      onClick: (event) => {
        callback(
          getActionObj(
            ACTION_TYPE.ACTION_ITEM_CLICK,
            feature.properties,
            event.target.id
          )
        );
      },
    });

    const amenitySection = Div({
      classNames: "cui_mapviewer_amenities_section",
    });

    UI.add(container, contentContainer);
    UI.add(container, actionBarContainer);

    UI.add(contentContainer, imgContainer);
    UI.add(contentContainer, facilitySummary);

    UI.add(facilitySummary, facilityTitleLink);

    if (uiData.errorText) {
      UI.add(
        facilitySummary,
        Div({
          content: uiData.errorText,
          classNames: "cui_mv_facility_summary_error_text",
        })
      );
    }

    UI.add(facilitySummary, amenitySection);
    UI.add(imgContainer, mainImage);

    if (uiData.amenityIcons && uiData.amenityIcons.length > 0) {
      const amenitySectionIconContainer = Div({
        classNames: "cui_mapviewer_amenities_section_icons_container",
      });
      UI.add(
        amenitySection,
        Div({
          content: "Has sites with",
          classNames: "cui_mapviewer_amenities_section_title",
        })
      );

      UI.add(amenitySection, amenitySectionIconContainer);

      uiData.amenityIcons.forEach((icon) => {
        UI.add(
          amenitySectionIconContainer,
          Image({ src: icon.iconSource, title: icon.title, alt: icon.title })
        );
      });
    }

    if (uiData.earliestAvailableDate) {
      UI.add(
        facilitySummary,
        Div({
          style: "font-size: 11px;",
          content: uiData.earliestAvailableDate,
        })
      );
    }

    if (uiData.actionButton) {
      UI.add(
        actionBarContainer,
        PopupActionButton({
          id: uiData.actionButton.id,
          label: uiData.actionButton.text,
          classNames: "cui_mv_submit_btn",
          isActionButton: true,
          onClick: (event) => {
            callback(
              getActionObj(
                ACTION_TYPE.ACTION_ITEM_CLICK,
                feature.properties,
                uiData.actionButton.id
              )
            );
          },
        })
      );
    }

    if (uiData.dailyEntry) {
        const dailyEntryContainer = Div({
          classNames: "cui_mv_dailyentry_container",
        });
        UI.add(container, dailyEntryContainer);
        UI.add(
            dailyEntryContainer,
            Div({
              content: uiData.dailyEntry.title,
              classNames: "cui_mv_dailyentry_container_title",
            })
        );
        if (uiData.dailyEntry.desc) {
            UI.add(
                dailyEntryContainer,
                Div({
                  content: uiData.dailyEntry.desc,
                  classNames: "cui_mv_dailyentry_container_desc",
                })
            );
        }
        UI.add(
            dailyEntryContainer,
            PopupActionButton({
              id: uiData.dailyEntry.buttonId,
              label: uiData.dailyEntry.buttonText,
              classNames: "cui_mv_submit_btn",
              isActionButton: true,
              onClick: (event) => {
                callback(
                  getActionObj(
                    ACTION_TYPE.ACTION_ITEM_CLICK,
                    feature.properties,
                    uiData.dailyEntry.buttonId
                  )
                );
              },
            })
        );
    }
    return container;
  }

  function getActionBtn() {}

  function getHighlightUI() {
    const highlightContainer = Div({
      content: "CUI Facility - Test Highlight",
    });

    return highlightContainer;
  }

  function getMainUI() {
    if (isEmpty(uiData)) {
      return null;
    }
    const mainContainer = PopupMainContainer({ mainStyle });

    let content = null;
    if (isHighlight) {
      content = getFullUI();
    } else {
      content = getFullUI();
    }

    UI.add(mainContainer, content);

    return mainContainer;
  }

  return getMainUI();
}

export default PopupCUIFacility;
