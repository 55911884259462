import { ACTION_TYPE, getActionObj } from "../../../../utils/ActionsUtils";
import { getValue, isEmpty } from "../../../../utils/GeneralUtils";

import Anchor from "../../base/Anchor";
import Label from "../../base/Label";
import Div from "../../base/Div";
import Image from "../../base/Image";
import Intl from "../../../../intl/Intl";
import PopupMainContainer from "../PopupMainContainer";
import UI from "../../base/UI";
import { addAttribute } from "../../../../uiBuilders/UIUtil";
// eslint-disable-next-line import/no-cycle
import { closePopups } from "../../../MapboxMapUtils";

const SITE_STATUS_ID = {
  CHECKOUT: 1000,
  OCCUPIED: 1001,
  CHECKIN: 1002,
  RESERVED: 1003,
  CLOSED: 1004,
  AVAILABLE: 1005,
  NOT_AVAILABLE: 1009,
  PARTIAL_AVAILABILITY: 1010,
};

const AVAILABILITY_STATUS = {
  AVAILABLE: "A",
  RESERVED: "R",
  NOT_AVAILABLE: "X",
};

const DEFAULT_ACTION_DISPLAY_COUNT = 2;
const SITE_TYPE_ICON_FOLDER_PATH = "/gis-maps/assets/icons/imageIcons";

function PopupFMSite({ feature, callback, mainStyle = "", isHover = false }) {
  const { properties } = feature;
  const { uiData } = properties;
  let statusId = null;

  function getSiteStatusId() {
    if (!isEmpty(uiData.baseInfo) && !isEmpty(uiData.baseInfo.siteStatusId)) {
      statusId = uiData.baseInfo.siteStatusId;
    }
  }

  function createIconOfBaseInfoContainer() {
    // Site type Icon
    let siteTypeIcon = null;
    if (
      !isEmpty(uiData.baseInfo) &&
      !isEmpty(uiData.baseInfo.isSiteTypeIconExisted) &&
      uiData.baseInfo.isSiteTypeIconExisted
    ) {
      siteTypeIcon = Image({
        id: `MapPopupSiteTypeIcon_${feature.properties.id}`,
        classNames: "",
        src: !isEmpty(feature.properties.popupIconPath)
          ? feature.properties.popupIconPath
          : feature.properties.iconPath,
        alt: !isEmpty(feature.properties.displayLabel)
          ? feature.properties.displayLabel
          : feature.properties.markerIconType,
        title: !isEmpty(feature.properties.displayLabel)
          ? feature.properties.displayLabel
          : feature.properties.markerIconType,
      });
    }

    // Icon of Base info container
    const siteStatusStyle =
      !isEmpty(uiData.baseInfo) && !isEmpty(uiData.baseInfo.siteStatusStyle)
        ? uiData.baseInfo.siteStatusStyle
        : "";
    const iconOfBaseInfoContainer = Div({
      id: "MapPopupIconOfBaseInfoContainer",
      classNames: `${siteStatusStyle} mapPopupIconOfBaseInfoContainer-${properties.styleId}`,
    });
    if (!isEmpty(siteTypeIcon)) {
      UI.add(iconOfBaseInfoContainer, siteTypeIcon);
    }

    return iconOfBaseInfoContainer;
  }

  function createContentOfBaseInfoContainer() {
    // Site code
    const siteCd = Div({
      id: feature.properties.id,
      classNames: "siteCd",
      content: isEmpty(feature.properties.siteCode)
        ? ""
        : feature.properties.siteCode,
      style: null,
      asSpan: true,
      onClick: null,
      role: "region",
      ariaLabel: "Site code",
    });
    // Site status
    let siteStatus = null;
    if (!isEmpty(statusId)) {
      if (SITE_STATUS_ID.OCCUPIED === statusId) {
        siteStatus = Div({
          id: statusId,
          classNames: "occupiedStatus",
          content:
            !isEmpty(uiData.baseInfo) &&
            !isEmpty(uiData.baseInfo.siteStatusName)
              ? uiData.baseInfo.siteStatusName
              : "",
          style: null,
          asSpan: true,
          onClick: null,
          role: "region",
          ariaLabel: "Site status",
        });
      } else if (SITE_STATUS_ID.RESERVED === statusId) {
        siteStatus = Div({
          id: statusId,
          classNames: "reservedStatus",
          content:
            !isEmpty(uiData.baseInfo) &&
            !isEmpty(uiData.baseInfo.siteStatusName)
              ? uiData.baseInfo.siteStatusName
              : "",
          style: null,
          asSpan: true,
          onClick: null,
          role: "region",
          ariaLabel: "Site status",
        });
      }
    }
    // Site code and status container
    const siteCdAndStatusContainer = Div({
      id: "MapPopupSiteCodeAndStatusContainer",
      classNames: "mapPopupSiteCodeAndStatusContainer",
    });
    UI.add(siteCdAndStatusContainer, siteCd);
    if (!isEmpty(siteStatus)) {
      UI.add(siteCdAndStatusContainer, siteStatus);
    }
    // Add list button
    let addListBtn = null;
    if (!isEmpty(uiData.baseInfo) && !isEmpty(uiData.baseInfo.addListBtn)) {
      addListBtn = Anchor({
        id: isEmpty(uiData.baseInfo.addListBtn.id)
          ? null
          : uiData.baseInfo.addListBtn.id,
        classNames: isEmpty(uiData.baseInfo.addListBtn.style)
          ? null
          : uiData.baseInfo.addListBtn.style,
        content: isEmpty(uiData.baseInfo.addListBtn.label)
          ? ""
          : uiData.baseInfo.addListBtn.label,
        url: null,
        onClick: (event) => {
          closePopups();
          callback(
            getActionObj(
              ACTION_TYPE.ACTION_ITEM_CLICK,
              feature.properties,
              event.target.id
            )
          );
        },
      });
    }

    let noactionLabel = null;
    // uiData.baseInfo.id===0 is meaning no-action, display a label.
    if (
      !isEmpty(uiData.baseInfo) &&
      !isEmpty(uiData.baseInfo.topright) &&
      uiData.baseInfo.topright.id === 0
    ) {
      noactionLabel = Label({
        classNames: uiData.baseInfo.topright.style,
        content: uiData.baseInfo.topright.label,
      });
    }
    // Site code and Add list button container
    const siteCdAndListContainer = Div({
      id: "MapPopupSiteCodeAndListContainer",
      classNames: "mapPopupSiteCodeAndListContainer",
    });
    UI.add(siteCdAndListContainer, siteCdAndStatusContainer);
    if (!isEmpty(noactionLabel)) {
      UI.add(siteCdAndListContainer, noactionLabel);
    } else if (!isEmpty(addListBtn)) {
      UI.add(siteCdAndListContainer, addListBtn);
    }

    // Site type and loop container(This container should be added to the base info container)
    const siteTypeAndLoopContainer = Div({
      id: "MapPopupSiteTypeAndLoopContainer",
      classNames: "mapPopupSiteTypeAndLoopContainer",
    });
    const siteType = Div({
      id: "MapPopupSiteType",
      classNames: "siteType",
      content:
        !isEmpty(uiData.baseInfo) && !isEmpty(uiData.baseInfo.siteType)
          ? uiData.baseInfo.siteType
          : "",
      style: null,
      asSpan: true,
    });
    UI.add(siteTypeAndLoopContainer, siteType);
    if (!isEmpty(uiData.baseInfo) && !isEmpty(uiData.baseInfo.loopArea)) {
      const loopArea = Div({
        id: "MapPopupLoopArea",
        classNames: "loopArea",
        content: uiData.baseInfo.loopArea,
        style: null,
        asSpan: true,
      });
      UI.add(siteTypeAndLoopContainer, loopArea);
    }

    // Content of Base info container
    const contentOfBaseInfoContainer = Div({
      id: "MapPopupContentOfBaseInfoContainer",
      classNames: "mapPopupContentOfBaseInfoContainer",
    });
    UI.add(contentOfBaseInfoContainer, siteCdAndListContainer);
    UI.add(contentOfBaseInfoContainer, siteTypeAndLoopContainer);

    return contentOfBaseInfoContainer;
  }

  function createBaseInfoSection() {
    // Base info container
    const baseInfoContainer = Div({
      id: "MapPopupSiteBaseInfoContainer",
      classNames: "mapPopupSiteBaseInfoContainer",
    });
    UI.add(baseInfoContainer, createIconOfBaseInfoContainer());
    UI.add(baseInfoContainer, createContentOfBaseInfoContainer());

    return baseInfoContainer;
    /* Sample HTML format as below
    <div id="MapPopupSiteBaseInfoContainer" class="checkinIcon">
      <div id="MapPopupSiteBaseInfoSubContainer" class="mapPopupSiteBaseInfoSubContainer">
        <div id="MapPopupIconOfBaseInfoContainer" class="mapPopupIconOfBaseInfoContainer">
        </div>
        <div id="MapPopupContentOfBaseInfoContainer" class="mapPopupContentOfBaseInfoContainer">
          <div id="MapPopupSiteCodeAndListContainer" class="mapPopupSiteCodeAndListContainer">
            <div id="MapPopupSiteCodeAndStatusContainer" class="mapPopupSiteCodeAndStatusContainer">
              <span class="siteCd">${siteCode}</span>
            </div>
            <a id="AddList" class="list">${addListBtn}</a>
          </div>
          <div id="MapPopupSiteTypeAndLoopContainer" class="mapPopupSiteTypeAndLoopContainer">
            <span class="siteType">${siteType}</span>
            <span class="loopArea">${loopArea}</span>
          </div>
        </div>
      </div>
    </div>
    */
  }

  function createAttributesSection() {
    const sections = getValue(() => uiData.sections, null);
    if (isEmpty(sections)) {
      return null;
    }
    const attrsContainer = Div({
      id: "MapPopupAttributesContainer",
      classNames: "mapPopupAttributesContainer",
    });
    // Fixed attributes container
    const fixedAttrsContainer = Div({
      id: "MapPopupFixedAttributesContainer",
      classNames: "mapPopupFixedAttributesContainer",
    });
    // Dynamic attributes container
    const dynamicAttrsContainer = Div({
      id: "MapPopupDynamicAttributesContainer",
      classNames: "mapPopupDynamicAttributesContainer",
    });
    sections.forEach((section) => {
      const attrs = getValue(() => section.attributes, null);
      if (!isEmpty(attrs)) {
        attrs.forEach((attr) => {
          if (attr.attrId === 12 || attr.attrId === 109) {
            // Fixed attributes(include Max number of people & Max vehicle length)
            const fixedAttr = Div({
              id: `MapPopupFixedAttribute_${attr.attrId}`,
              classNames: attr.style,
              content: attr.value,
              style: null,
              asSpan: true,
              onClick: null,
              role: "image",
              ariaLabel: `${attr.title}: ${attr.value}`,
            });
            addAttribute("title", attr.title, fixedAttr);
            UI.add(fixedAttrsContainer, fixedAttr);
          } else {
            const dynamicAttrWrap = Div({
              id: `MapPopupDynamicAttributeWrap_${attr.attrId}`,
              classNames: "mapPopupDynamicAttributeWrap",
            });
            const dynamicAttr = Div({
              id: `MapPopupDynamicAttribute_${attr.attrId}`,
              classNames: attr.style,
              content: "",
              style: null,
              asSpan: true,
              onClick: null,
              role: "image",
              ariaLabel: attr.title,
            });
            addAttribute("title", attr.title, dynamicAttr);
            UI.add(dynamicAttrWrap, dynamicAttr);
            UI.add(dynamicAttrsContainer, dynamicAttrWrap);
          }
        });
      }
    });
    UI.add(attrsContainer, fixedAttrsContainer);
    UI.add(attrsContainer, dynamicAttrsContainer);

    return attrsContainer;
    /* Sample HTML format as below
    <div id="MapPopupAttributesContainer" class="mapPopupAttributesContainer">
      <div id="MapPopupFixedAttributesContainer" class="mapPopupFixedAttributesContainer">
        <span id="12" class="maxNumOfPeople">${maxNumOfPeople}</span>
        <span id="109" class="maxVehLength">${maxVehLength}</span>
      </div>
      <div id="MapPopupDynamicAttributesContainer" class="mapPopupDynamicAttributesContainer">
        <div id="MapPopupDynamicAttributeWrap" class="mapPopupDynamicAttributeWrap">
          <span id="220" class="i-pets-allowed">${dynamicAttributes}</span>
        </div>
      </div>
    </div>
    */
  }

  function createAvailibilityGridSection() {
    // Left part, site is available then display available text otherwise didplay reservation info
    const leftPartContainer = Div({
      id: "MapPopupGridLeftPartContainer",
      classNames: "mapPopupGridLeftPartContainer",
    });
    if (!isEmpty(statusId)) {
      if (
        SITE_STATUS_ID.AVAILABLE === statusId ||
        SITE_STATUS_ID.CLOSED === statusId ||
        SITE_STATUS_ID.NOT_AVAILABLE === statusId ||
        SITE_STATUS_ID.PARTIAL_AVAILABILITY === statusId
      ) {
        let className = "available";
        if (SITE_STATUS_ID.CLOSED === statusId) {
          className = "closed";
        } else if (SITE_STATUS_ID.NOT_AVAILABLE === statusId || SITE_STATUS_ID.PARTIAL_AVAILABILITY === statusId) {
          className = "notavailable";
        }
        const statusInfo = Div({
          id: "MapPopupGridStatusInfo",
          classNames: className,
          content:
            !isEmpty(uiData.baseInfo) &&
            !isEmpty(uiData.baseInfo.siteStatusName)
              ? uiData.baseInfo.siteStatusName
              : "",
          style: null,
          asSpan: true,
          onClick: null,
          role: "region",
          ariaLabel: "Site status",
        });
        const statusInfoContainer = Div({
          id: "MapPopupStatusInfoContainer",
          classNames: "mapPopupStatusInfoContainer",
        });
        UI.add(statusInfoContainer, statusInfo);
        UI.add(leftPartContainer, statusInfoContainer);
      } else if (!isEmpty(uiData.reservation)) {
        const resvNum = Div({
          id: "MapPopupGridReservationNumber",
          classNames: "resverationNumber",
          content:
            !isEmpty(uiData.reservation) && !isEmpty(uiData.reservation.resvNum)
              ? uiData.reservation.resvNum
              : "",
          style: null,
          asSpan: true,
          onClick: null,
          role: "region",
          ariaLabel: "Reservation number",
        });
        const resvNumContainer = Div({
          id: "MapPopupResvNumContainer",
          classNames: "mapPopupResvNumContainer",
        });
        UI.add(resvNumContainer, resvNum);
        UI.add(leftPartContainer, resvNumContainer);

        const custName = Div({
          id: "MapPopupGridCustomerName",
          classNames: "customerName",
          content:
            !isEmpty(uiData.reservation) &&
            !isEmpty(uiData.reservation.custName)
              ? uiData.reservation.custName
              : "",
          style: null,
          asSpan: true,
          onClick: null,
          role: "region",
          ariaLabel: "Customer name",
        });
        const custNameContainer = Div({
          id: "MapPopupCustNameContainer",
          classNames: "mapPopupCustNameContainer",
        });
        UI.add(custNameContainer, custName);
        UI.add(leftPartContainer, custNameContainer);

        const custPhone = Div({
          id: "MapPopupGridCustomerPhone",
          classNames: "customerPhone",
          content:
            !isEmpty(uiData.reservation) &&
            !isEmpty(uiData.reservation.custPhone)
              ? uiData.reservation.custPhone
              : "",
          style: null,
          asSpan: true,
          onClick: null,
          role: "region",
          ariaLabel: "Customer phone number",
        });
        const custPhoneContainer = Div({
          id: "MapPopupCustPhoneContainer",
          classNames: "mapPopupCustPhoneContainer",
        });
        UI.add(custPhoneContainer, custPhone);
        UI.add(leftPartContainer, custPhoneContainer);
      }
    }
    // Right part, display availibility grid
    let rightPartContainer = null;
    const girdDays = getValue(() => uiData.availabilityGrid.days, null);
    if (!isEmpty(girdDays)) {
      rightPartContainer = Div({
        id: "MapPopupGridRightPartContainer",
        classNames: "mapPopupGridRightPartContainer",
      });
      let currMonth = null;
      girdDays.forEach((day) => {
        const monthInd = day.monthIndicator;
        if (monthInd) {
          currMonth = day.month;
          const monthCellContainer = Div({
            id: `MapPopupGridMonthCellContainer_${currMonth}`,
            classNames: "mapPopupGridMonthCellContainer",
          });
          const monthCell = Div({
            id: `MapPopupGridMonthCell_${currMonth}`,
            classNames: "month",
            content: currMonth,
            style: null,
            asSpan: true,
            onClick: null,
            role: "region",
            ariaLabel: "Month",
          });
          UI.add(monthCellContainer, monthCell);
          UI.add(rightPartContainer, monthCellContainer);
        } else {
          // Week day
          const weekDayCell = Div({
            id: `MapPopupGridWeekDayCell_${currMonth}_${day.day}`,
            classNames: "mapPopupGridWeekDayCell",
            content: day.day,
            style: null,
            asSpan: true,
            onClick: null,
            role: "region",
            ariaLabel: "Day",
          });
          // Date
          const dateCell = Div({
            id: `MapPopupGridDateCell_${currMonth}_${day.date}`,
            classNames: "mapPopupGridDateCell",
            content: day.date,
            style: null,
            asSpan: true,
            onClick: null,
            role: "region",
            ariaLabel: "Date",
          });
          // Availability
          const availabilityCellContainer = Div({
            id: `MapPopupGridAvailabilityCellContainer_${currMonth}_${day.date}_${day.availability}`,
            classNames: "mapPopupGridAvailabilityCellContainer",
          });
          const availabilityCell = Div({
            id: `MapPopupGridAvailabilityCell_${currMonth}_${day.date}_${day.availability}`,
            classNames: "mapPopupGridAvailabilityCell",
          });
          let availabilityIconContainer = Div({
            id: `MapPopupGridAvailabilityIconContainer_${currMonth}_${day.date}_${day.availability}`,
            classNames: "available",
          });
          let availabilityIcon = Div({
            id: `MapPopupGridAvailabilityIcon_${currMonth}_${day.date}_${day.availability}`,
            classNames: "mapPopupGridAvailabilityIconGreen",
            content: "",
            style: null,
            asSpan: true,
            onClick: null,
            role: null,
            ariaLabel: "available",
          });
          if (day.availability === AVAILABILITY_STATUS.RESERVED) {
            availabilityIconContainer = Div({
              id: `MapPopupGridAvailabilityIconContainer_${currMonth}_${day.date}_${day.availability}`,
              classNames: "unavailableRed",
            });
            availabilityIcon = Div({
              id: `MapPopupGridAvailibilityIcon_${currMonth}_${day.date}_${day.availability}`,
              classNames: "mapPopupGridAvailabilityIconRed",
              content: "",
              style: null,
              asSpan: true,
              onClick: null,
              role: null,
              ariaLabel: "reserved",
            });
          } else if (day.availability === AVAILABILITY_STATUS.NOT_AVAILABLE) {
            availabilityIconContainer = Div({
              id: `MapPopupGridAvailabilityIconContainer_${currMonth}_${day.date}_${day.availability}`,
              classNames: "unavailableYellow",
            });
            availabilityIcon = Div({
              id: `MapPopupGridAvailabilityIcon_${currMonth}_${day.date}_${day.availability}`,
              classNames: "mapPopupGridAvailabilityIconYellow",
              content: "",
              style: null,
              asSpan: true,
              onClick: null,
              role: null,
              ariaLabel: "unavailable",
            });
          }
          UI.add(availabilityIconContainer, availabilityIcon);
          UI.add(availabilityCell, availabilityIconContainer);
          UI.add(availabilityCellContainer, availabilityCell);
          // Combine cell
          const combineCellContainer = Div({
            id: `MapPopupGridDateCombineContainer_${currMonth}_${day.date}`,
            classNames: "mapPopupGridDateCombineContainer",
          });
          UI.add(combineCellContainer, weekDayCell);
          UI.add(combineCellContainer, dateCell);
          UI.add(combineCellContainer, availabilityCellContainer);
          UI.add(rightPartContainer, combineCellContainer);
        }
      });
    }
    // Availibility Grid Section
    const gridSectionContainer = Div({
      id: "MapPopupGridSectionContainer",
      classNames: "mapPopupGridSectionContainer",
    });
    UI.add(gridSectionContainer, leftPartContainer);
    if (!isEmpty(rightPartContainer))
      UI.add(gridSectionContainer, rightPartContainer);

    return gridSectionContainer;
    /* Sample HTML format as below
    <div id="MapPopupGridSectionContainer" class="mapPopupGridSectionContainer">
      <div id="MapPopupGridLeftPartContainer" class="mapPopupGridLeftPartContainer">
        <div id="MapPopupResvNumContainer" class="mapPopupResvNumContainer">
          <span id="MapPopupGridReservationNumber" class="resverationNumber">${resvNum}</span>
        </div>
        <div id="MapPopupCustNameContainer" class="mapPopupCustNameContainer">
          <span id="MapPopupGridCustomerName" class="customerName">${custName}</span>
        </div>
        <div id="MapPopupCustPhoneContainer" class="mapPopupCustPhoneContainer">
          <span id="MapPopupGridCustomerPhone" class="customerPhone">${custPhone}</span>
        </div>
      </div>
      <div id="MapPopupGridRightPartContainer" class="mapPopupGridRightPartContainer">
        <div id="MapPopupGridMonthCellContainer" class="mapPopupGridMonthCellContainer">
          <span id="MapPopupGridMonthCell" class="month">${month}</span>
        </div>
        <div id="MapPopupGridDateCombineContainer" class="mapPopupGridDateCombineContainer">
          <span id="MapPopupGridWeekDayCell" class="mapPopupGridWeekDayCell">${day}</span>
          <span id="MapPopupGridDateCell" class="mapPopupGridDateCell">${date}</span>
          <div id="MapPopupGridAvailabilityCellContainer"
            class="mapPopupGridAvailabilityCellContainer">
            <div id="MapPopupGridAvailabilityCell" class="mapPopupGridAvailabilityCell">
              <div id="MapPopupGridAvailabilityIconContainer" class="available">
                <span id="MapPopupGridAvailabilityIcon"
                  class="mapPopupGridAvailabilityIconGreen">${availabilityIcon}</span>
              </div>
            </div>
          </div>
        </div>
        <div id="MapPopupGridDateCombineContainer" class="mapPopupGridDateCombineContainer">
          <span id="MapPopupGridWeekDayCell" class="mapPopupGridWeekDayCell">${day}</span>
          <span id="MapPopupGridDateCell" class="mapPopupGridDateCell">${date}</span>
          <div id="MapPopupGridAvailabilityCellContainer"
            class="mapPopupGridAvailabilityCellContainer">
            <div id="MapPopupGridAvailabilityCell" class="mapPopupGridAvailabilityCell">
              <div id="MapPopupGridAvailabilityIconContainer" class="available">
                <span id="MapPopupGridAvailabilityIcon"
                  class="mapPopupGridAvailabilityIconGreen">${availabilityIcon}</span>
              </div>
            </div>
          </div>
        </div>
        <div id="MapPopupGridDateCombineContainer" class="mapPopupGridDateCombineContainer">
          <span id="MapPopupGridWeekDayCell" class="mapPopupGridWeekDayCell">${day}</span>
          <span id="MapPopupGridDateCell" class="mapPopupGridDateCell">${date}</span>
          <div id="MapPopupGridAvailabilityCellContainer"
            class="mapPopupGridAvailabilityCellContainer">
            <div id="MapPopupGridAvailabilityCell" class="mapPopupGridAvailabilityCell">
              <div id="MapPopupGridAvailabilityIconContainer" class="available">
                <span id="MapPopupGridAvailabilityIcon"
                class="mapPopupGridAvailabilityIconGreen">${availabilityIcon}</span>
              </div>
            </div>
          </div>
        </div>
        <div id="MapPopupGridDateCombineContainer" class="mapPopupGridDateCombineContainer">
          <span id="MapPopupGridWeekDayCell" class="mapPopupGridWeekDayCell">${day}</span>
          <span id="MapPopupGridDateCell" class="mapPopupGridDateCell">${date}</span>
          <div id="MapPopupGridAvailabilityCellContainer"
            class="mapPopupGridAvailabilityCellContainer">
            <div id="MapPopupGridAvailabilityCell" class="mapPopupGridAvailabilityCell">
              <div id="MapPopupGridAvailabilityIconContainer" class="available">
                <span id="MapPopupGridAvailabilityIcon"
                  class="mapPopupGridAvailabilityIconRed">${availabilityIcon}</span>
              </div>
            </div>
          </div>
        </div>
        <div id="MapPopupGridDateCombineContainer" class="mapPopupGridDateCombineContainer">
          <span id="MapPopupGridWeekDayCell" class="mapPopupGridWeekDayCell">${day}</span>
          <span id="MapPopupGridDateCell" class="mapPopupGridDateCell">${date}</span>
          <div id="MapPopupGridAvailabilityCellContainer"
            class="mapPopupGridAvailabilityCellContainer">
            <div id="MapPopupGridAvailabilityCell" class="mapPopupGridAvailabilityCell">
              <div id="MapPopupGridAvailabilityIconContainer" class="available">
                <span id="MapPopupGridAvailabilityIcon"
                class="mapPopupGridAvailabilityIconYellow">${availabilityIcon}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    */
  }

  function createFixedActionButtonsSection(actions) {
    // Fixed actions
    const actionsContainer = Div({
      id: "MapPopupActionsContainer",
      classNames: "mapPopupActionsContainer",
    });
    let fixedActionDisplayCount = 1;
    actions.forEach((action) => {
      if (fixedActionDisplayCount <= DEFAULT_ACTION_DISPLAY_COUNT) {
        const actionButton = Anchor({
          id: action.id,
          classNames: action.style,
          content: action.label,
          url: null,
          onClick: (event) => {
            closePopups();
            callback(
              getActionObj(
                ACTION_TYPE.ACTION_ITEM_CLICK,
                feature.properties,
                event.target.id
              )
            );
          },
        });
        UI.add(actionsContainer, actionButton);
        fixedActionDisplayCount += 1;
      }
    });
    // Dynamic actions control button
    let controlButtonContainer = null;
    if (actions.length > DEFAULT_ACTION_DISPLAY_COUNT) {
      const controlButton = Anchor({
        id: "MapPopupDynamicActionsControlButton",
        classNames: "mapPopupDynamicActionsControlButtonDown",
        content: "",
        url: null,
        onClick: (event) => {
          callback(
            getActionObj(
              ACTION_TYPE.ACTION_ITEM_CLICK,
              feature.properties,
              event.target.id
            )
          );
        },
      });
      controlButtonContainer = Div({
        id: "MapPopupDynamicActionsControlButtonContainer",
        classNames: "mapPopupDynamicActionsControlButtonContainer",
      });
      UI.add(controlButtonContainer, controlButton);
    }
    // Fixed actions and dynamic actions control button container
    const fixedActionsContainer = Div({
      id: "MapPopupFixedActionsContainer",
      classNames: "mapPopupFixedActionsContainer",
    });
    UI.add(fixedActionsContainer, actionsContainer);
    if (!isEmpty(controlButtonContainer))
      UI.add(fixedActionsContainer, controlButtonContainer);

    return fixedActionsContainer;
    /* Sample HTML format as below
    <div id="MapPopupFixedActionsContainer" class="mapPopupFixedActionsContainer">
      <div id="MapPopupActionsContainer" class="mapPopupActionsContainer">
        <a id="2" class="action-icon-transfer">${fixedAction 1}</a>
        <a id="3" class="action-icon-datechange">${fixedAction 2}</a>
      </div>
      <div id="MapPopupDynamicActionsControlButtonContainer"
        class="mapPopupDynamicActionsControlButtonContainer">
        <a id="MapPopupDynamicActionsControlButton"
          class="MapPopupDynamicActionsControlButtonDown">
        </a>
      </div>
    </div>
    */
  }

  function createDynamicActionButtonsSection(actions) {
    const dynamicActionsContainer = Div({
      id: "MapPopupDynamicActionsContainer",
      classNames: "mapPopupDynamicActionsContainer",
    });
    const dynamicActionsWrapContainer = Div({
      id: "MapPopupDynamicActionsWrapContainer",
      classNames: "mapPopupDynamicActionsWrapContainer",
    });
    let action = null;
    for (let i = DEFAULT_ACTION_DISPLAY_COUNT; i < actions.length; i += 1) {
      action = actions[i];
      const actionButton = Anchor({
        id: action.id,
        classNames: action.style,
        content: action.label,
        url: null,
        onClick: (event) => {
          closePopups();
          callback(
            getActionObj(
              ACTION_TYPE.ACTION_ITEM_CLICK,
              feature.properties,
              event.target.id
            )
          );
        },
      });
      UI.add(dynamicActionsWrapContainer, actionButton);
    }
    UI.add(dynamicActionsContainer, dynamicActionsWrapContainer);

    return dynamicActionsContainer;
    /* Sample HTML format as below
    <div id="MapPopupDynamicActionsContainer" class="mapPopupDynamicActionsContainer">
      <a id="4"  class="action-icon-void">${dynamicAction 1}</a>
      <a id="5"  class="action-icon-noshow">${dynamicAction 2}</a>
      <a id="11" class="action-icon-cancel">${dynamicAction 3}</a>
      <a id="6"  class="action-icon-checkin">${dynamicAction 4}</a>
    </div>
    */
  }

  function getClickMainUI() {
    getSiteStatusId();
    const mainContainer = PopupMainContainer({ mainStyle });
    // Create base info section
    UI.add(mainContainer, createBaseInfoSection());
    // Create attributes section
    const attrSection = createAttributesSection();
    if (!isEmpty(attrSection)) {
      UI.add(mainContainer, attrSection);
    }
    // Create availibility grid section
    UI.add(mainContainer, createAvailibilityGridSection());
    // Create fixed action and dynamic buttons section
    const actions = getValue(() => uiData.mainActions, null);
    if (!isEmpty(actions)) {
      // Fixed actions
      UI.add(mainContainer, createFixedActionButtonsSection(actions));
      // Dynamic actions
      if (actions.length > DEFAULT_ACTION_DISPLAY_COUNT) {
        UI.add(mainContainer, createDynamicActionButtonsSection(actions));
      }
    }
    return mainContainer;
  }

  function getHoverHead() {
    const containerUI = Div({ id: "head", classNames: "head" });
    let title = getValue(() => properties.name, null);
    if (isEmpty(title)) {
      title = Intl.simpleTranslate(
        `markerIconType_${uiData.head.titleTextFallbackIntlToken}`,
        uiData.head.titleTextFallbackIntlToken
      );
      if (
        !isEmpty(properties.displayLabel) &&
        title === uiData.head.titleTextFallbackIntlToken
      ) {
        title = properties.displayLabel;
      }
    }
    if (!isEmpty(title)) {
      const titleUI = Div({
        classNames: "gismaps-popup-title",
        content: title,
      });
      UI.add(containerUI, titleUI);
    }

    return containerUI;
  }

  function getHoverMainUI() {
    const mainContainer = PopupMainContainer({ mainStyle });
    UI.add(mainContainer, getHoverHead());
    return mainContainer;
  }

  function getMainUI() {
    if (isHover || uiData.head.featureType === "amenity") {
      return getHoverMainUI();
    }

    // Get site status id
    const mainContainer = getClickMainUI();

    return mainContainer;
  }

  return getMainUI();
}

export default PopupFMSite;
