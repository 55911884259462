import Button from '../Button'
import Intl from '../../../../intl/Intl'
import {isUndefined} from '../../../../utils/GeneralUtils'

function CloseButton({onClick}) {
  function getUI() {
    const closeButton = Button({
      classNames: 'a1mv__close',
      ariaLabel: Intl.simpleTranslate('closeBtnLbl'),
      onClick: () => {
        if (!isUndefined(onClick)) {
          onClick()
        }
      }
    })

    return closeButton
  }

  return getUI()
}

export default CloseButton
