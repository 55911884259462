/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
const logMsgs = false

export function log(msg, obj = null) {
  if (logMsgs) {
    if (obj !== null) {
      console.log(msg, obj)
    } else {
      console.log(msg)
    }
  }
}

export function isEmptyObj(obj = null) {
  if (Object.keys(obj).length === 0 && obj.constructor === Object) {
    return true
  }

  return false
}

export function isEmptyArray(value) {
  if (Array.isArray(value) && value.length === 0) {
    return true
  }

  return false
}

export function isUndefined(value) {
  if (typeof value === 'undefined') {
    return true
  }

  return false
}

export function isEmpty(value) {
  if (!isUndefined(value) && value !== null && value !== '' && !isEmptyObj(value) && !isEmptyArray(value)) {
    return false
  }

  return true
}

export function getValue(func, fallbackValue = null) {
  try {
    const value = func()
    return value === null || value === undefined ? fallbackValue : value
  } catch (e) {
    return fallbackValue
  }
}

export function findObjInArray(targetArray, key, value) {
  let obj = null
  // eslint-disable-next-line no-restricted-syntax
  for (const item of targetArray) {
    if (item[key] === value) {
      obj = item
      break
    }
  }

  return obj
}

export function parseIfRequired(strOrObj) {
  let parsedObj = null
  try {
    parsedObj = JSON.parse(strOrObj)
    if (typeof parsedObj === 'object') {
      return parsedObj
    }
  } catch (e) {
    return strOrObj
  }
  return strOrObj
}

export function hackFixJSON(obj) {
  for (const [key, value] of Object.entries(obj)) {
    obj[key] = parseIfRequired(value)
  }
}

export function replaceAll(str, find, replacement) {
  return str.split(find).join(replacement)
}

// Using xhr for compatibility - even in ES6
export function loadData(path, callback) {
  const xhr = new XMLHttpRequest()
  xhr.onreadystatechange = function innerFunc() {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (xhr.status === 200) {
        callback(true, JSON.parse(xhr.responseText))
      } else {
        callback(false, xhr)
      }
    }
  }

  xhr.open('GET', path, true)
  xhr.send()

  // setTimeout(() => {
  //   xhr.open('GET', path, true)
  //   xhr.send()
  // }, 5000)
}

// DO NOT USE
// export function loadMultiData(paths = [], callback) {
//   const responses = []
//   let allLoaded = true
//   paths.forEach((path) => {
//     loadData(path, (success, data) => {
//       if (!success) {
//         allLoaded = false
//       }
//       responses.push(data)
//       if (responses.length === paths.length) {
//         callback(allLoaded, responses)
//       }
//     })
//   })
// }

/**
* Performs a deep merge of objects and returns new object. Does not modify
* objects (immutable) and merges arrays via concatenation.
*
* @param {...object} objects - Objects to merge
* @returns {object} New object with merged key/values
*/
export function mergeDeep(...objects) {
  const isObject = (obj) => obj && typeof obj === 'object'

  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach((key) => {
      const pVal = prev[key]
      const oVal = obj[key]

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = pVal.concat(...oVal)
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal)
      } else {
        prev[key] = oVal
      }
    })

    return prev
  }, {})
}

export function cloneDeepJSON(jsonData) {
  return JSON.parse(JSON.stringify(jsonData))
}
