import Button from '../base/Button'

function PopupActionButton({
  id = '', classNames = '', label = '', disabled = false, onClick = null
}) {
  function getMainUI() {
    const actionButton = Button({
      id, classNames, label, disabled, isActionButton: true, onClick
    })

    return actionButton
  }

  return getMainUI()
}

export default PopupActionButton
