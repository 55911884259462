export const MAP_ACTION_TYPE = {
  ITEM_CLICK: 'itemClick',
  DATA_CHANGE: 'dataChange'
}

export const MAP_ACTION_ID = {
  PERSPECTIVE_BUTTON: 'perspectiveButton',
  MAP_STYLE_BUTTON: 'mapStyleButton',
  RECENTER_BUTTON: 'recenterButton',
  CATEGORY_DATA: 'categoryData'
}

export const MAP_ACTION_STYLE = {
  MAP_STYLE_BUTTON_STYLE: 'a1mv__icon-map-style'
}

export const MAP_ACTION_BUTTON_ID = {
  MAP_STYLE_BUTTON_ID: 'map-style-button'
}

export function getMapActionObj(type, properties, id = null) {
  return {
    actionType: type,
    targetProperties: properties,
    id
  }
}
