import PopupCUIFacility from '../map/components/popups/cui/PopupCUIFacility'
import PopupCUIHunter from '../map/components/popups/cui/PopupCUIHunter'
import PopupCUIIssuer from '../map/components/popups/cui/PopupCUIIssuer'
import PopupFMSite from '../map/components/popups/fm/PopupFMSite'
import PopupFMSiteTest from '../map/components/popups/fm/PopupFMSiteTest'
import PopupPLSite from '../map/components/popups/pl/PopupPLSite'
import PopupFMSlip from '../map/components/popups/fm/PopupFMSlip'
import PopupRAFacility from '../map/components/popups/PopupRAFacility'
import PopupRAFacilityTest from '../map/components/popups/PopupRAFacilityTest'
import PopupRASite from '../map/components/popups/ra/PopupRASite'
import PopupShape from '../map/components/popups/default/PopupShape'
import {isEmpty} from './GeneralUtils'

export const STYLE_TYPE = {
  FM_SITES_TEST: 'fmSitesTest',
  RA_FACILITIES_TEST: 'raFacilitiesTest',
  FM_SITES: 'fmSites',
  FM_SLIPS: 'fmSlips',
  RA_FACILITIES: 'raFacilities',
  RA_SITES: 'raSites',
  PL_SITES: 'plSites',
  CUI_FACILITIES: 'cuiFacilities',
  CUI_HUNTER: 'cuiHunter',
  CUI_ISSUERS: 'cuiIssuers'
}

// export const SHAPE_POPUPS = {
//   styles: [
//     {
//       styleId: STYLE_TYPE.FM_SITES,
//       active: false
//     },
//     {
//       styleId: STYLE_TYPE.RA_SITES,
//       active: false
//     }
//   ]
// }

export function getPopupContentByStyle(
  feature,
  clientCallback,
  style,
  isHighlight = false,
  isHover = false
) {
  if (style === STYLE_TYPE.FM_SITES_TEST) {
    return PopupFMSiteTest({
      feature, callback: clientCallback, mainStyle: style, isHighlight, isHover
    })
  }
  if (style === STYLE_TYPE.RA_FACILITIES_TEST) {
    return PopupRAFacilityTest(feature, clientCallback, style, isHighlight)
  }

  if (style === STYLE_TYPE.FM_SITES) {
    return PopupFMSite({
      feature, callback: clientCallback, mainStyle: style, isHighlight, isHover
    })
  }

  if (style === STYLE_TYPE.FM_SLIPS) {
    return PopupFMSlip({
      feature, callback: clientCallback, mainStyle: style, isHighlight, isHover
    })
  }
  
  if (style === STYLE_TYPE.RA_FACILITIES) {
    return PopupRAFacility(feature, clientCallback, style, isHighlight)
  }
  if (style === STYLE_TYPE.RA_SITES) {
    return PopupRASite(feature, clientCallback, style, isHighlight, isHover)
  }
  if (style === STYLE_TYPE.PL_SITES) {
    return PopupPLSite(feature, clientCallback, style, isHighlight, isHover)
  }
  if (style === STYLE_TYPE.CUI_FACILITIES) {
    return PopupCUIFacility(feature, clientCallback, style, isHighlight)
  }
  if (style === STYLE_TYPE.CUI_HUNTER) {
    return PopupCUIHunter(feature, clientCallback, style, isHighlight, isHover)
  }
  if (style === STYLE_TYPE.CUI_ISSUERS) {
    return PopupCUIIssuer(feature, clientCallback, style, isHighlight)
  }
  return null
}

export function getShapePopupContentByStyle(feature, clientCallback, style, isHover = false) {
  // Only have default impl for now
  return PopupShape(feature, clientCallback, style, isHover)
}

export function isPopupRenderable(feature) {
  if (!isEmpty(feature.properties) && !isEmpty(feature.properties.uiData)) {
    return true
  }

  return false
}

// export function isShapePopupRenderableForStyle(style) {
//   const shapePopupconfig = findObjInArray(SHAPE_POPUPS.styles, 'styleId', style)

//   if (!isEmpty(shapePopupconfig) && shapePopupconfig.active) {
//     return true
//   }

//   return false
// }
