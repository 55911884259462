import {getValue, isEmpty} from '../../../../utils/GeneralUtils'
import Div from '../../base/Div'
import UI from '../../base/UI'

function PopupSections({uiData = null}) {
  function getSectionsUI() {
    const containerUI = Div({classNames: 'section row'})
    const sections = getValue(() => uiData.sections, null)

    if (!isEmpty(sections)) {
      sections.forEach((section) => {
        const attributes = getValue(() => section.attributes, null)
        if (!isEmpty(attributes)) {
          const leftCol = Div({classNames: 'column'})
          const rightCol = Div({classNames: 'column'})
          let last = 2
          attributes.forEach((attribute) => {
            const attributeUI = Div({content: `${attribute.title} - ${attribute.value}`})
            if (last === 2) {
              UI.add(leftCol, attributeUI)
              last = 1
            } else {
              UI.add(rightCol, attributeUI)
              last = 2
            }
          })
          UI.add(containerUI, leftCol)
          UI.add(containerUI, rightCol)
        }
      })
    }

    return containerUI
  }
  function getMainUI() {
    const mainContainer = Div({})
    const sectionsUI = getSectionsUI()
    if (!isEmpty(sectionsUI)) {
      UI.add(mainContainer, sectionsUI)
    }

    return mainContainer
  }

  return getMainUI()
}

export default PopupSections
