/* eslint-disable max-len */
import {
  isEmpty, hackFixJSON, getValue
} from '../../../utils/GeneralUtils'
import {getButton, getDiv, getMainContainer} from '../../../uiBuilders/UIUtil'
import {ACTION_TYPE, getActionObj} from '../../../utils/ActionsUtils'

function PopupRAFacilityTest(feature, callback, mainStyle = '', isHighlight = false) {
  // TODO - not sure why the uiData gets stringified when passed in - fix.
  hackFixJSON(feature.properties)
  const {uiData} = feature.properties
  function getHead() {
    const containerUI = getDiv('head', 'head')
    const title = getValue(() => uiData.head.title, null)
    if (!isEmpty(title)) {
      const titleUI = getDiv(null, 'gismaps-popup-title', uiData.head.title)
      containerUI.appendChild(titleUI)
    }

    return containerUI
  }

  function getActionButtons() {
    const containerUI = getDiv('action-buttons')

    if (!isEmpty(uiData.mainActions)) {
      const actionItems = uiData.mainActions
      actionItems.forEach((action) => {
        const button = getButton(action.id, action.style, action.label, action.disabled)
        button.addEventListener('click', (event) => {
          callback(getActionObj(ACTION_TYPE.ACTION_ITEM_CLICK, feature.properties, event.target.id))
        })

        containerUI.appendChild(button)
      })
    }

    return containerUI
  }

  function getMainUI() {
    const mainContainer = getMainContainer(null, mainStyle)
    mainContainer.appendChild(getHead())
    mainContainer.appendChild(getActionButtons())

    return mainContainer
  }

  return getMainUI()
}

export default PopupRAFacilityTest
