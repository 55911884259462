import {getImage} from '../../../uiBuilders/UIUtil'
import {isEmpty} from '../../../utils/GeneralUtils'

function Image({
  id = null, classNames = '', src = null, alt = '', title = '', onClick = null, defaultImg = '',
  inlineStyle = ''
}) {
  function getUI() {
    const img = getImage(id, classNames, src, alt, title, inlineStyle)

    if (!isEmpty(defaultImg)) {
      const addDefaultImage = function addDefaultImg() {
        img.removeEventListener('error', addDefaultImage)
        img.src = defaultImg
      }
      img.addEventListener('error', addDefaultImage)
    }

    if (!isEmpty(onClick)) {
      img.addEventListener('click', (event) => {
        onClick(event)
      })
    }

    return img
  }

  return getUI()
}

export default Image
