/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
/**
 * @file   : Intl.js
 * @author : Amarjeet Lamba (amarjeet.lamba@aspiraconnect.com)
 * @date   : 8/19/2020, 11:49:25 AM
 */
/** ****************************************
 *                                         *
 *              FRAMEWORK                  *
 *  (Follow Framework Editing Guidelines)  *
 *          (See Readme Files)             *
 *                                         *
 ***************************************** */

/** ***********************************************
 *  NOTE                                          *
 *  This is a direct port from another            *
 *  project and that is why we have so            *
 *  many lint disables.                           *
 *  This has been done to save time for now.      *
 *  TODO - Fix later.                           *
 ************************************************ */

// import enUs from './en-us.json'
// import frCa from './fr-ca.json'
import * as utils from '../utils/GeneralUtils'

export const LOCALES = {
  EN_US: 'enUs',
  FR_CA: 'frCa'
}

class IntlImpl {
  constructor() {
    this.intlObj = null
    this.applicableQualifiers = []
  }

  _translateWorker(
    path,
    literalValues,
    translateValues,
    defaultMessage,
    allowNull,
    baseMessage = null
  ) {
    // let baseMessage = null

    if (!baseMessage) {
      baseMessage = this._translate(path, defaultMessage, allowNull)
    }

    if (!baseMessage) {
      return null
    }

    const substitutions = {}

    if (translateValues) {
      for (const tprop in translateValues) {
        substitutions[tprop] = this._translate(translateValues[tprop])
      }
    }

    if (literalValues) {
      for (const lprop in literalValues) {
        substitutions[lprop] = literalValues[lprop]
      }
    }

    if (substitutions) {
      for (const prop in substitutions) {
        const replaceKey = `{${prop}}`
        baseMessage = utils.replaceAll(baseMessage, replaceKey, substitutions[prop])
      }
    }

    return baseMessage
  }

  addQualifier(qualifier) {
    this.applicableQualifiers.push(qualifier)
  }

  clearQualifiers() {
    this.applicableQualifiers = []
  }

  translate(
    path,
    literalValues,
    translateValues,
    defaultMessage,
    allowNull = false
  ) {
    if (utils.isEmpty(this.intlObj)) {
      return ''
    }
    let baseMessage = null

    // eslint-disable-next-line max-len
    if (!utils.isUndefined(this.applicableQualifiers) && !utils.isEmpty(this.applicableQualifiers)) {
      const arrlength = this.applicableQualifiers.length
      for (let i = arrlength; i > 0; i -= 1) {
        this.applicableQualifiers.length = i
        const translatedMessage = this._translate(`${path}_${this.applicableQualifiers.join('_')}`, defaultMessage, true)
        if (!utils.isEmpty(translatedMessage)) {
          baseMessage = translatedMessage
          break
        }
      }
    }

    // eslint-disable-next-line max-len
    return this._translateWorker(path, literalValues, translateValues, defaultMessage, allowNull, baseMessage)
  }

  simpleTranslate(path, defaultMessage = '') {
    if (utils.isEmpty(this.intlObj)) {
      return ''
    }
    // return this._translateWorker(path, null, null, defaultMessage, false)
    return this.translate(path, null, null, defaultMessage, false)
  }

  // translate(path, literalValues, translateValues, defaultMessage, allowNull) {
  //   // eslint-disable-next-line max-len
  //   return this._translateWorker(path, literalValues, translateValues, defaultMessage, allowNull)
  // }

  // setLocale(locale) {
  //   if (locale === LOCALES.EN_US) {
  //     this.intlObj = enUs
  //   } else if (locale === LOCALES.FR_CA) {
  //     this.intlObj = frCa
  //   }
  // }

  setLocale(localeObj) {
    this.intlObj = localeObj
  }

  _translate(path, defaultMessage, allowNull) {
    const pathParts = [path]
    let message
    try {
      message = pathParts.reduce((obj, pathPart) => obj[pathPart], this.intlObj)
    } finally {
      if (!message) {
        if (allowNull) {
          // eslint-disable-next-line no-unsafe-finally
          return null
        }
        if (defaultMessage) {
          message = defaultMessage // return the default...
        } else {
          // message = TRANSLATION_NOT_FOUND + ':' + path // return the not found message...
          message = ''
        }
      }
    }

    return message
  }
}

// Singleton Instance.
let instance = null

// eslint-disable-next-line one-var
const getInstance = function getInstanceFn() {
  if (!instance) {
    instance = new IntlImpl()
  }

  return instance
}

// Exposed methods
export default {get: getInstance}
