/* eslint-disable max-len */
import { isEmpty, hackFixJSON } from "../../../../utils/GeneralUtils";
import { ACTION_TYPE, getActionObj } from "../../../../utils/ActionsUtils";
import PopupMainContainer from "../PopupMainContainer";
import UI from "../../base/UI";
import Div from "../../base/Div";
import Anchor from "../../base/Anchor";

function PopupCUIIssuer(
  feature,
  callback,
  mainStyle = "",
  isHighlight = false
) {
  // TODO - not sure why the uiData gets stringified when passed in - fix.
  hackFixJSON(feature.properties);
  const { uiData } = feature.properties;

  function getFullUI() {
    const container = Div({ classNames: "cui_issuer_mv_popup_container" });
    const titleDiv = Div({
      classNames: "cui_issuer_mv_popup_title",
      content: uiData.title.text,
    });
    // const titleAnchor = Anchor({
    //   id: uiData.title.id,
    //   content: uiData.title.text,
    //   onClick: (event) => {
    //     callback(
    //       getActionObj(
    //         ACTION_TYPE.ACTION_ITEM_CLICK,
    //         feature.properties,
    //         event.target.id
    //       )
    //     );
    //   },
    // });
    const fullAddressDiv = Div({ content: uiData.fullAddress });
    const businessPhone = Div({ content: uiData.businessPhone });
    const directionLinkDiv = Div({});
    const directionLinkAnchor = Anchor({
      id: uiData.direction.id,
      content: uiData.direction.text,
      onClick: (event) => {
        callback(
          getActionObj(
            ACTION_TYPE.ACTION_ITEM_CLICK,
            feature.properties,
            event.target.id
          )
        );
      },
    });
    UI.add(directionLinkDiv, directionLinkAnchor);
    // UI.add(titleDiv, titleAnchor);
    UI.add(container, titleDiv);
    UI.add(container, fullAddressDiv);
    UI.add(container, businessPhone);
    UI.add(container, directionLinkDiv);
    return container;
  }

  function getActionBtn() {}

  function getHighlightUI() {
    const highlightContainer = Div({
      content: "CUI Facility - Test Highlight",
    });

    return highlightContainer;
  }

  function getMainUI() {
    if (isEmpty(uiData)) {
      return null;
    }
    const mainContainer = PopupMainContainer({ mainStyle });

    let content = null;
    if (isHighlight) {
      content = getFullUI();
    } else {
      content = getFullUI();
    }

    UI.add(mainContainer, content);

    return mainContainer;
  }

  return getMainUI();
}

export default PopupCUIIssuer;
