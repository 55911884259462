import { ACTION_TYPE, getActionObj } from '../../../../utils/ActionsUtils'
import { getValue, isEmpty } from '../../../../utils/GeneralUtils'

import Anchor from '../../base/Anchor'
import Div from '../../base/Div'
import Image from '../../base/Image'
import Intl from '../../../../intl/Intl'
import PopupMainContainer from '../PopupMainContainer'
import UI from '../../base/UI'
import { addAttribute } from '../../../../uiBuilders/UIUtil'
// eslint-disable-next-line import/no-cycle
import { closePopups } from '../../../MapboxMapUtils'

const SLIP_STATUS_ID = {
  UNDOCKING: 1000,
  OCCUPIED: 1001,
  DOCKING: 1002,
  RESERVED: 1003,
  CLOSED: 1004,
  AVAILABLE: 1005,
  SHARED: 1006,
  UNKNOWN: 1007,
  LOTTERY: 1008,
  NOT_AVAILABLE: 1009,
  WAITING_LIST: 1010
}

const AVAILABILITY_STATUS = {
  AVAILABLE: 'A',
  RESERVED: 'R',
  NOT_AVAILABLE: 'X'
}

const DEFAULT_ACTION_DISPLAY_COUNT = 4
const SITE_TYPE_ICON_FOLDER_PATH = '/gis-maps/assets/icons/imageIcons'

function PopupFMSlip({
  feature, callback, mainStyle = '', isHover = false
}) {
  const { properties } = feature
  const { uiData } = properties
  let statusId = null

  function getSiteStatusId() {
    if (!isEmpty(uiData.baseInfo) && !isEmpty(uiData.baseInfo.siteStatusId)) {
      statusId = uiData.baseInfo.siteStatusId
    }
  }

  function createIconOfBaseInfoContainer(slipReservationType) {
    // Site type Icon
    let siteTypeIcon = null
    if (!isEmpty(uiData.baseInfo) && !isEmpty(uiData.baseInfo.isSiteTypeIconExisted)
      && uiData.baseInfo.isSiteTypeIconExisted) {
      siteTypeIcon = Image({
        id: `MapPopupSiteTypeIcon_${feature.properties.id}`,
        classNames: '',
        src: !isEmpty(feature.properties.popupIconPath) ? feature.properties.popupIconPath : feature.properties.iconPath,
        alt: !isEmpty(feature.properties.displayLabel) ? feature.properties.displayLabel : feature.properties.markerIconType,
        title: !isEmpty(feature.properties.displayLabel) ? feature.properties.displayLabel : feature.properties.markerIconType
      })
    }
    let slipStatusStyle = ''
    if(slipReservationType === 'Transient'){
      slipStatusStyle =  (!isEmpty(uiData.baseInfo) && !isEmpty(uiData.baseInfo.siteStatusStyle)) ? uiData.baseInfo.siteStatusStyle : ''
    }else{
      slipStatusStyle =  ((!isEmpty(uiData.baseInfo) && !isEmpty(uiData.baseInfo.slipStatusStyleForSeasonalLease)) ? "colorPalette_" + uiData.baseInfo.slipStatusStyleForSeasonalLease+ "_original" : '') 
    }
    const iconOfBaseInfoContainer = Div({
      id: 'MapPopupIconOfBaseInfoContainer',
      classNames: `${slipStatusStyle} mapPopupIconOfBaseInfoContainer-${properties.styleId}`
    })
    if (!isEmpty(siteTypeIcon)) {
      UI.add(iconOfBaseInfoContainer, siteTypeIcon)
    }

    return iconOfBaseInfoContainer
  }

  function createContentOfBaseInfoContainer(slipReservationType) {
    // Site code
    const siteCd = Div({
      id: feature.properties.id,
      classNames: 'siteCd',
      content: isEmpty(feature.properties.siteCode) ? '' : feature.properties.siteCode,
      style: null,
      asSpan: true,
      onClick: null,
      role: 'region',
      ariaLabel: 'Site code'
    })
    // slip status
    let siteStatus = null

    if (slipReservationType === 'Transient') {
      if (!isEmpty(statusId)) {
        if (SLIP_STATUS_ID.OCCUPIED === statusId) {
          siteStatus = Div({
            id: statusId,
            classNames: 'occupiedStatus',
            content: (!isEmpty(uiData.baseInfo) && !isEmpty(uiData.baseInfo.siteStatusName)) ? uiData.baseInfo.siteStatusName : '',
            style: null,
            asSpan: true,
            onClick: null,
            role: 'region',
            ariaLabel: 'Site status'
          })
        } else if (SLIP_STATUS_ID.RESERVED === statusId) {
          siteStatus = Div({
            id: statusId,
            classNames: 'reservedStatus',
            content: (!isEmpty(uiData.baseInfo) && !isEmpty(uiData.baseInfo.siteStatusName)) ? uiData.baseInfo.siteStatusName : '',
            style: null,
            asSpan: true,
            onClick: null,
            role: 'region',
            ariaLabel: 'Site status'
          })
        }
      }
    } else {
      if (!isEmpty(uiData.baseInfo.slipStatusForSeasonalLeaseId)) {
        if (SLIP_STATUS_ID.OCCUPIED === uiData.baseInfo.slipStatusForSeasonalLeaseId) {
          siteStatus = Div({
            id: statusId,
            classNames: 'occupiedStatus',
            content: (!isEmpty(uiData.baseInfo) && !isEmpty(uiData.baseInfo.slipStatusNameForSeasonalLease)) ? uiData.baseInfo.slipStatusNameForSeasonalLease : '',
            style: null,
            asSpan: true,
            onClick: null,
            role: 'region',
            ariaLabel: 'Site status'
          })
        } else if (SLIP_STATUS_ID.RESERVED === uiData.baseInfo.slipStatusForSeasonalLeaseId) {
          siteStatus = Div({
            id: statusId,
            classNames: 'reservedStatus',
            content: (!isEmpty(uiData.baseInfo) && !isEmpty(uiData.baseInfo.slipStatusNameForSeasonalLease)) ? uiData.baseInfo.slipStatusNameForSeasonalLease : '',
            style: null,
            asSpan: true,
            onClick: null,
            role: 'region',
            ariaLabel: 'Site status'
          })
        }
      }
    }
    // Site code and status container
    const siteCdAndStatusContainer = Div({
      id: 'MapPopupSiteCodeAndStatusContainer', classNames: 'mapPopupSiteCodeAndStatusContainer'
    })
    UI.add(siteCdAndStatusContainer, siteCd)
    if (!isEmpty(siteStatus)) {
      UI.add(siteCdAndStatusContainer, siteStatus)
    }

    // Site code container
    const siteCdAndListContainer = Div({ id: 'MapPopupSiteCodeAndListContainer', classNames: 'mapPopupSiteCodeAndListContainer' })
    UI.add(siteCdAndListContainer, siteCdAndStatusContainer)

    // Slip type and dock container(This container should be added to the base info container)
    const siteTypeAndLoopContainer = Div({ id: 'MapPopupSiteTypeAndLoopContainer', classNames: 'MapPopupSiteCodeAndListContainer' })

    const slipType = "<div>Slip Type: " + uiData.baseInfo.siteType + "</div>";
    const slipDock = "<div>Dock: " + uiData.baseInfo.slipDock + "</div>";
    const containerHeaderInfo = "<div>" + slipDock + slipType + "</div>";
    const slipTypeCointainer = Div({
      id: 'MapPopupSlipType',
      classNames: 'slipType',
      content: containerHeaderInfo,
      style: null,
      asSpan: true
    })
    UI.add(siteTypeAndLoopContainer, slipTypeCointainer)

    // Content of Base info container
    const contentOfBaseInfoContainer = Div({ id: 'MapPopupContentOfBaseInfoContainer', classNames: 'mapPopupContentOfBaseInfoContainer' })
    UI.add(contentOfBaseInfoContainer, siteCdAndListContainer)
    UI.add(contentOfBaseInfoContainer, siteTypeAndLoopContainer)

    return contentOfBaseInfoContainer
  }

  function createBaseInfoSection(slipReservationType) {
    // Base info container
    const baseInfoContainer = Div({
      id: 'MapPopupSlipBaseInfoContainer',
      classNames: 'mapPopupSlipBaseInfoContainer'
    })
    UI.add(baseInfoContainer, createIconOfBaseInfoContainer(slipReservationType))
    UI.add(baseInfoContainer, createContentOfBaseInfoContainer(slipReservationType))

    return baseInfoContainer
  }

  function createAttributesSection() {
    const attrsContainer = Div({ id: 'SlipMapPopupAttributesContainer', classNames: 'mapSlipPopupAttributesContainer' })
    // Fixed attributes container
    const attributeBoatLength = "<div class='boatAttribute'><div class='iconLength'></div><span class='boatAttributeValue'>" + uiData.baseInfo.boatLength + "' long</span></div>";
    const attributeBoatWidth = "<div class='boatAttribute'><div class='iconWidth'></div><span class='boatAttributeValue'>" + uiData.baseInfo.boatWidth + "' wide</span></div>";
    const attributeBoatDepth = "<div class='boatAttribute'><div class='iconDepth'></div><span class='boatAttributeValue'>" + uiData.baseInfo.boatDepth + "' deep</span></div>";
    const attributeBoatLengthLimitation = "<div class='boatAttribute'><div class='iconBoat'></div><span class='boatAttributeValue'>" + uiData.baseInfo.boatMinLength + "' min - " + uiData.baseInfo.boatMaxLength + "' max</span></div>";
    const fixedAttrsContainer = Div(
      {
        id: 'MapSlipPopupFixedAttributesContainer',
        classNames: 'mapSlipPopupFixedAttributesContainer',
        content: attributeBoatLength + attributeBoatWidth + attributeBoatDepth + attributeBoatLengthLimitation
      });
    UI.add(attrsContainer, fixedAttrsContainer);
    // UI.add(attrsContainer, dynamicAttrsContainer)

    return attrsContainer;
  }

  function createAvailibilityGridSection(slipReservationType) {
    // Left part, site is available then display available text otherwise didplay reservation info
    const leftPartContainer = Div({ id: 'MapPopupGridLeftPartContainer', classNames: 'mapPopupGridLeftPartContainer' });
    let reservationsByType = [];
    if (uiData.reservation) {
      reservationsByType = uiData.reservation.filter(res => res.resvType == slipReservationType);
    }
    let rightPartContainer = null;
    if (!isEmpty(statusId)) {
      // if (SLIP_STATUS_ID.AVAILABLE === statusId || SLIP_STATUS_ID.CLOSED === statusId || SLIP_STATUS_ID.NOT_AVAILABLE === statusId || !isEmpty(reservationsByType)) {

      if (!isEmpty(reservationsByType)) {
        createReservationContainer(leftPartContainer, reservationsByType);
      }

      let slipStatusNameForCurrentTab = ''
      let slipStatusIDForCurrentTab = ''
      let availLength = ''
      if(slipReservationType === 'Transient'){
        slipStatusNameForCurrentTab = uiData.baseInfo.siteStatusName;
        slipStatusIDForCurrentTab = uiData.baseInfo.siteStatusId;
        availLength = uiData.availLengthTransient;
      }else{
        slipStatusNameForCurrentTab = uiData.baseInfo.slipStatusNameForSeasonalLease;
        slipStatusIDForCurrentTab = uiData.baseInfo.slipStatusForSeasonalLeaseId;
        availLength = uiData.availLength;
      }
      let iconStatus = '<i class="far fa-calendar-check"></i>';
      if (SLIP_STATUS_ID.CLOSED === slipStatusIDForCurrentTab) {
        iconStatus = '<i class="far fa-calendar-times"></i>';
      } else if (SLIP_STATUS_ID.NOT_AVAILABLE === slipStatusIDForCurrentTab) {
        iconStatus = '<i class="far fa-calendar-times"></i>';
      }
      const slipStatus = "<div class='slipStatus'>" + iconStatus + slipStatusNameForCurrentTab + "</div>";
      const slipReservationTypeLabel = "<div class='slipReservationType'><i class='fas fa-list-alt'></i>" + slipReservationType + "</div>";

      const slipLong = "<div class='slipLong'><i class='fas fa-anchor'></i>" + "Available: " + availLength + "' Long" + "</div>";
      const container = "<div>" + slipStatus + slipReservationTypeLabel + slipLong + "</div>";

      const statusInfo = Div({
        id: 'MapPopupGridStatusInfo',
        content: container,
        style: null,
        asSpan: true,
        onClick: null,
        role: 'region',
        ariaLabel: 'Site status'
      })
      const statusInfoContainer = Div({ id: 'MapPopupStatusInfoContainer', classNames: 'mapPopupStatusInfoContainer' })
      UI.add(statusInfoContainer, statusInfo)
      UI.add(leftPartContainer, statusInfoContainer)
      if (slipReservationType == "Seasonal" || slipReservationType == "Lease") {
        rightPartContainer = Div({ id: 'MapSeasonSlipPopupGridRightPartContainer', classNames: 'mapSeasonSlipPopupGridRightPartContainer' })
      } else {
        rightPartContainer = Div({ id: 'MapPopupGridRightPartContainer', classNames: 'mapPopupGridRightPartContainer' })
      }

      if (slipReservationType == "Seasonal") {
        createGridForSeasonView(rightPartContainer);
      } else if (slipReservationType == "Lease") {
        createGridForLeaseView(rightPartContainer);
      }
      else {
        createGridForTransientView(rightPartContainer);
      }
    }
    const availInfoSectionContainer = Div({ classNames: 'mapSlipPopupAvailInfoSectionContainer' })

    // Availibility Grid Section
    const gridSectionContainer = Div({ id: 'MapSlipPopupGridSectionContainer', classNames: `mapSlipPopupGridSectionContainer mapSlipPopupGrid-${slipReservationType}` })
    const gridAvailContainer = Div({ id: 'MapSlipPopupGridAvailContainer', classNames: `mapSlipPopupGridAvailContainer mapSlipPopupGrid-${slipReservationType}` })
    UI.add(gridSectionContainer, leftPartContainer);
    if (!isEmpty(rightPartContainer)) UI.add(gridSectionContainer, rightPartContainer);
    if (!isEmpty(reservationsByType)) {
      createReservationContainer(availInfoSectionContainer, reservationsByType);
    }

    let flag = false;
    if (slipReservationType === "Transient" && uiData.availLengthTransient > 0) {
      flag = true;
    } else if ((slipReservationType === "Seasonal" || slipReservationType === "Lease") && uiData.availLength > 0) {
      flag = true;
    }



    if (flag) {
      UI.add(gridAvailContainer, gridSectionContainer);
      if (slipReservationType == "Seasonal" || slipReservationType == "Lease") {
        createActionButons(gridAvailContainer, uiData.availActionForSeasonLease, null, slipReservationType)
      }
      else if (slipReservationType == "Transient") {
        createActionButons(gridAvailContainer, uiData.availActionForTransient, null, slipReservationType)
      }
      UI.add(availInfoSectionContainer, gridAvailContainer);

    } else {
      if (isEmpty(reservationsByType)) {
        if (uiData.baseInfo.siteStatusId === SLIP_STATUS_ID.NOT_AVAILABLE || uiData.baseInfo.siteStatusId === SLIP_STATUS_ID.CLOSED || uiData.baseInfo.siteStatusId === SLIP_STATUS_ID.UNKNOWN || uiData.baseInfo.siteStatusId === SLIP_STATUS_ID.RESERVED) {
          UI.add(gridAvailContainer, gridSectionContainer);
          UI.add(availInfoSectionContainer, gridAvailContainer);
        }
      }
    }


    return availInfoSectionContainer;
  }

  function createReservationContainer(panel, reservations) {
    let firstReservation = true
    const reservationInfoContainer = Div({
      id: 'MapPopupGridStatusInfoContainer',
      content: '',
      style: null,
      asSpan: false,
      onClick: null
    });
    let flag = false;
    if (reservations[0].resvType === "Transient" && uiData.availLengthTransient == 0) {
      flag = true;
    } else if ((reservations[0].resvType === "Seasonal" || reservations[0].resvType === "Lease") && uiData.availLength == 0) {
      flag = true;
    }
    reservations.forEach(res => {
      const reservationId = "<div class='slipReservationId'><div class='slip-info-icon'></div><b>" + `${res.resvNum}` + "</b></div>";
      const reservationArrival = "<div class='slipReservationArrival'><div class='slip-info-icon'></div>" + `<b>Arrival: </b> ${res.arrivalDate}` + "</div>";
      const reservationBoatName = "<div class='slipReservationBoatName'><div class='slip-info-icon'></div>" + `<b>Boat Name: </b> ${res.boatName}` + "</div>";
      const reservationCustomerName = "<div class='slipReservationCustomerName'><div class='slip-info-icon'></div>" + `${res.custName}` + "</div>";
      const reservationStatus = "<div class='slipReservationStatus'><div class='slip-info-icon'></div>" + `<b>Status:</b> ${res.resvStatus}` + "</div>";
      const reservationDeparture = "<div class='slipReservationDeparture'><div class='slip-info-icon'></div>" + `<b>Departure:</b> ${res.departureDate}` + "</div>";
      const reservationBoatLength = "<div class='slipReservationBoatLength'><div class='slip-info-icon'></div>" + `<b>Boat Length: </b> ${res.boatLength} ` + "</div>";
      const reservationPhoneNumber = "<div class='slipReservationPhoneNumber'><div class='slip-info-icon'></div>" + res.custPhone + "</div>";

      const container = "<div class='reservationInfoContainer'>" + reservationId + reservationStatus + reservationArrival + reservationDeparture + reservationBoatName + reservationBoatLength + reservationCustomerName + reservationPhoneNumber + "</div>";
      const statusInfo = Div({
        id: 'MapPopupGridStatusInfo',
        content: container,
        style: null,
        asSpan: false,
        onClick: null,
        classNames: `reservation-${res.resvNum} reservation-slip-tab-content-${res.resvType} ${flag && firstReservation ? "displayFlex" : "displayNone"}`
      });
      UI.add(reservationInfoContainer, statusInfo);
      createActionButons(statusInfo, res.availableActions, res.resvNum, null)
      firstReservation = false;
    });

    const statusInfoContainer = Div({ id: 'MapPopupStatusInfoReservationContainer-' + reservations[0].resvType, classNames: `mapPopupStatusInfoReservationContainer ${flag ? "displayFlex" : "displayNone"}` });
    UI.add(statusInfoContainer, reservationInfoContainer);
    UI.add(panel, statusInfoContainer);
  }

  function createAvilTab(reservationTabs, slipReservationType) {
    const availTab = Div({
      id: `avail-tab`,
      content: `AVAILABLE`,
      style: null,
      asSpan: false,
      classNames: `tablink-slip activeLink tab-` + slipReservationType,
      onClick: () => {
        // Declare all variables
        var i, tabcontent, tablinks;

        // Get all elements with class="tabcontent" and hide them
        tabcontent = document.getElementsByClassName("reservation-slip-tab-content");
        for (i = 0; i < tabcontent.length; i++) {
          tabcontent[i].classList.add("displayNone");
          tabcontent[i].classList.remove("displayFlex");
        }

        // Get all elements with class="tablinks" and remove the class "active"
        tablinks = document.getElementsByClassName("tablink-slip");
        for (i = 0; i < tablinks.length; i++) {
          tablinks[i].classList.remove("activeLink");
        }
        document.getElementById(`avail-tab`).classList.add("activeLink");
        document.getElementById("MapPopupStatusInfoReservationContainer-" + slipReservationType).classList.add("displayNone");
        document.getElementById("MapPopupStatusInfoReservationContainer-" + slipReservationType).classList.remove("displayFlex");

        let element = document.getElementsByClassName(`mapSlipPopupGrid-${slipReservationType}`);
        if (element != null) {
          element[0].classList.add("displayFlex");
          element[0].classList.remove("displayNone");
          element[0].classList.add("active");
        }
      }
    })
    UI.add(reservationTabs, availTab);
  }

  function createTabsForReservations(mainContainer, reservations, slipReservationType) {
    const reservationTabs = Div({
      id: 'reservationTabs',
      content: '',
      style: null,
      asSpan: false,
      classNames: 'slip-tab tap-' + slipReservationType
    })

    let avail = 0;
    if (slipReservationType === "Transient") {
      avail = uiData.availLengthTransient;
    } else if ((slipReservationType === "Seasonal" || slipReservationType === "Lease")) {
      avail = uiData.availLength;
    }

    if (avail > 0 ) {
      createAvilTab(reservationTabs, slipReservationType);
    }
    let firstReservation = true;
    reservations.forEach(res => {
      const resTab = Div({
        id: `${res.resvNum}`,
        content: `${res.resvNum}`,
        style: null,
        asSpan: false,
        classNames: `tablink-slip ${(avail == 0 && reservations.length == 1) || (avail == 0 && firstReservation) ? "activeLink" : ""} tab-` + res.resvType,
        onClick: () => {
          // Declare all variables
          var i, tabcontent, tablinks;

          // Get all elements with class="tabcontent" and hide them
          tabcontent = document.getElementsByClassName(`reservation-slip-tab-content-${res.resvType}`);
          for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].classList.add("displayNone");
            tabcontent[i].classList.remove("displayFlex");
          }
          let slipGrid = document.getElementsByClassName(`mapSlipPopupGrid-${slipReservationType}`);
          if (slipGrid.length > 0) {
            slipGrid[0].classList.add("displayNone");
            slipGrid[0].classList.remove("displayFlex");
          }

          // Get all elements with class="tablinks" and remove the class "active"
          tablinks = document.getElementsByClassName("tablink-slip tab-" + res.resvType);
          for (i = 0; i < tablinks.length; i++) {
            tablinks[i].classList.remove("activeLink");
          }
          document.getElementById(`${res.resvNum}`).classList.add("activeLink");
          document.getElementById("MapPopupStatusInfoReservationContainer-" + slipReservationType).classList.add("displayFlex");
          document.getElementById("MapPopupStatusInfoReservationContainer-" + slipReservationType).classList.remove("displayNone");
          // Show the current tab, and add an "active" class to the button that opened the tab
          let elements = document.getElementsByClassName(`reservation-${res.resvNum}`);
          if (elements.length > 0) {
            elements[0].classList.add("displayFlex");
            elements[0].classList.remove("displayNone");
            elements[0].classList.add("active");
          }

        }
      });
      UI.add(reservationTabs, resTab);
      firstReservation = false;
    })

    const reservationContainerWithArrows = Div({
      id: 'reservationContainerWithArrows',
      content: '',
      style: null,
      asSpan: false,
      classNames: 'reservationContainerWithArrows'
    });
    const arrowLeft = Div({
      id: "arrowLeft",
      content: `<i class="fas fa-angle-left"></i>`,
      style: null,
      asSpan: false,
      classNames: `left-arrow`,
      onClick: () => {
        const reservationTabs = document.getElementById(`reservationTabs`);
        reservationTabs.scrollBy({
          left: -200,
          behavior: "smooth"
        });
      }
    });
    const arrowRight = Div({
      id: "arrowRight",
      content: `<i class="fas fa-angle-right"></i>`,
      style: null,
      asSpan: false,
      classNames: `right-arrow`,
      onClick: () => {
        const reservationTabs = document.getElementById(`reservationTabs`);
        reservationTabs.scrollBy({
          left: 200,
          behavior: "smooth"
        });
      }
    });
    if (reservations.length > 3) {
      UI.add(reservationContainerWithArrows, arrowLeft);
    }

    UI.add(reservationContainerWithArrows, reservationTabs);
    if (reservations.length > 3) {
      UI.add(reservationContainerWithArrows, arrowRight);
    }
    UI.add(mainContainer, reservationContainerWithArrows);
  }

  function createGridForLeaseView(panel) {
    let girdDays = getValue(() => uiData.availabilityGridForSeasonalOrLease.days, null);
    if (!isEmpty(girdDays)) {
      const seasonName = "<div class='seasonName'>&nbsp;</div>"
      const endDate = uiData.slipAvailInfo[0].leaseDepartureDate;
      const dateCurrentDate = new Date(girdDays[1].year, girdDays[1].month, girdDays[1].date);
      const dateLeaseEndDate = new Date(endDate.year, endDate.month, endDate.dayOfMonth);

      const month1 = dateCurrentDate.toLocaleString('default', { month: 'short' });
      const month2 = dateLeaseEndDate.toLocaleString('default', { month: 'short' });

      const startDateFormatter = month1 + " " + dateCurrentDate.getDate();
      const endDateFormatter = month2 + " " + endDate.dayOfMonth;

      const seasonDates = "<div class='seasonDates'>" + startDateFormatter + " - " + endDateFormatter + "</div>"

      createGridLayout(girdDays, dateCurrentDate, dateLeaseEndDate, seasonName, seasonDates, panel);
    }
  }

  function createGridForSeasonView(panel) {
    let girdDays = getValue(() => uiData.availabilityGridForSeasonalOrLease.days, null);
    if (!isEmpty(girdDays)) {
      let seasonName = '';
      if (uiData.baseInfo.season) {
        seasonName = "<div class='seasonName'>" + uiData.baseInfo.season.name + "</div>"
      }

      const startDate = uiData.baseInfo.season.startDate;
      const endDate = uiData.baseInfo.season.endDate;

      const dateSeasonStart = new Date(startDate.year, startDate.month, startDate.dayOfMonth);
      const dateSeasonEnd = new Date(endDate.year, endDate.month, endDate.dayOfMonth);

      const month1 = dateSeasonStart.toLocaleString('default', { month: 'short' });
      const month2 = dateSeasonEnd.toLocaleString('default', { month: 'short' });

      const startDateFormatter = month1 + " " + startDate.dayOfMonth;
      const endDateFormatter = month2 + " " + endDate.dayOfMonth;

      const seasonDates = "<div class='seasonDates'>" + startDateFormatter + " - " + endDateFormatter + "</div>"


      createGridLayout(girdDays, dateSeasonStart, dateSeasonEnd, seasonName, seasonDates, panel);
    }
  }

  function createActionButons(panel, actionsData, resvNum, slipReservationType) {
    const actions = getValue(() => actionsData, null)
    if (!isEmpty(actions)) {
      // Fixed actions
      UI.add(panel, createFixedActionButtonsSection(actions, resvNum, slipReservationType))
      // Dynamic actions
      if (actions.length > DEFAULT_ACTION_DISPLAY_COUNT) {
        UI.add(panel, createDynamicActionButtonsSection(actions, resvNum, slipReservationType))
      }
    }
  }

  function createGridLayout(girdDays, dateSeasonStart, dateSeasonEnd, seasonName, seasonDates, panel) {
    splitByMonth(girdDays).forEach((month) => {
      let htmlGridDays = ""
      let htmlGridStatusIcon = ""
      let statusCount = 0
      let monthLabel = ''
      month.forEach((day) => {
        const monthInd = day.monthIndicator
        if (monthInd) {
          // createMonthCell(panel, day);
          monthLabel = day.month
        } else {
          let dateUI = new Date(day.year, day.month, day.date)
          // Extracting dates without time component
          const dateUIWithoutTime = new Date(dateUI.getFullYear(), dateUI.getMonth(), dateUI.getDate())
          const dateSeasonStartWithoutTime = new Date(dateSeasonStart.getFullYear(), dateSeasonStart.getMonth(), dateSeasonStart.getDate())
          const dateSeasonEndWithoutTime = new Date(dateSeasonEnd.getFullYear(), dateSeasonEnd.getMonth(), dateSeasonEnd.getDate())

          if (dateUIWithoutTime >= dateSeasonStartWithoutTime && dateUIWithoutTime <= dateSeasonEndWithoutTime) {

            htmlGridDays += `<div class='grid-day-cell'><div>${day.day}</div> <div>${day.date}</div></div>`
          } else {
            htmlGridDays += `<div class='grid-day-cell'><div>${day.day}</div> <div>${day.date}</div></div>`
            htmlGridStatusIcon += "<div class='statusDay-icon'></div>"
            statusCount += 1
          }
        }

      })
      const seasonNameClass = "seasonNameClass-" + (month.length - 1)
      const toolTip = "<div class='seasonName-tooltip'>" + seasonName + seasonDates + "</div>"
      const statusContainer = statusCount != 5 ? "<div class='status'>" + seasonName + seasonDates + toolTip + "</div>" : ""
      const container = "<div class='labelMonthGrid'>" + monthLabel + "</div><div class='seasonGridStatus'><div class='days'>" + htmlGridDays + "</div><div class='gridStatus " + seasonNameClass + "'>" + htmlGridStatusIcon + statusContainer + "</div></div>"
      const grid = Div({
        content: container,
        classNames: 'seasonGrid',
        style: null,
        onClick: null,
      })
      UI.add(panel, grid)
    })
  }

  function splitByMonth(data) {
    const result = [];
    let currentMonthArray = [];

    data.forEach(obj => {
      if (obj.monthIndicator) {
        currentMonthArray = [obj];
        result.push(currentMonthArray);
      } else {
        currentMonthArray.push(obj);
      }
    });

    return result;
  };

  function createGridCell(panel, currMonth, day) {
    // Week day
    const weekDayCell = Div({
      id: `MapPopupGridWeekDayCell_${currMonth}_${day.day}`,
      classNames: 'mapPopupGridWeekDayCell',
      content: day.day,
      style: null,
      asSpan: true,
      onClick: null,
      role: 'region',
      ariaLabel: 'Day'
    })
    // Date
    const dateCell = Div({
      id: `MapPopupGridDateCell_${currMonth}_${day.date}`,
      classNames: 'mapPopupGridDateCell',
      content: day.date,
      style: null,
      asSpan: true,
      onClick: null,
      role: 'region',
      ariaLabel: 'Date'
    })
    // Availability
    const availabilityCellContainer = Div({
      id: `MapPopupGridAvailabilityCellContainer_${currMonth}_${day.date}_${day.availability}`,
      classNames: 'mapPopupGridAvailabilityCellContainer'
    })
    const availabilityCell = Div({
      id: `MapPopupGridAvailabilityCell_${currMonth}_${day.date}_${day.availability}`,
      classNames: 'mapPopupGridAvailabilityCell'
    })
    let availabilityIconContainer = Div({
      id: `MapPopupGridAvailabilityIconContainer_${currMonth}_${day.date}_${day.availability}`,
      classNames: 'available'
    })
    let availabilityIcon = Div({
      id: `MapPopupGridAvailabilityIcon_${currMonth}_${day.date}_${day.availability}`,
      classNames: 'mapPopupGridAvailabilityIconGreen',
      content: '',
      style: null,
      asSpan: true,
      onClick: null,
      role: null,
      ariaLabel: 'available'
    })
    if (day.availability === AVAILABILITY_STATUS.RESERVED) {
      availabilityIconContainer = Div({
        id: `MapPopupGridAvailabilityIconContainer_${currMonth}_${day.date}_${day.availability}`,
        classNames: 'unavailableRed'
      })
      availabilityIcon = Div({
        id: `MapPopupGridAvailibilityIcon_${currMonth}_${day.date}_${day.availability}`,
        classNames: 'mapPopupGridAvailabilityIconRed',
        content: '',
        style: null,
        asSpan: true,
        onClick: null,
        role: null,
        ariaLabel: 'reserved'
      })
    } else if (day.availability === AVAILABILITY_STATUS.NOT_AVAILABLE) {
      availabilityIconContainer = Div({
        id: `MapPopupGridAvailabilityIconContainer_${currMonth}_${day.date}_${day.availability}`,
        classNames: 'unavailableYellow'
      })
      availabilityIcon = Div({
        id: `MapPopupGridAvailabilityIcon_${currMonth}_${day.date}_${day.availability}`,
        classNames: 'mapPopupGridAvailabilityIconYellow',
        content: '',
        style: null,
        asSpan: true,
        onClick: null,
        role: null,
        ariaLabel: 'unavailable'
      })
    }
    UI.add(availabilityIconContainer, availabilityIcon)
    UI.add(availabilityCell, availabilityIconContainer)
    UI.add(availabilityCellContainer, availabilityCell)
    // Combine cell
    const combineCellContainer = Div({
      id: `MapPopupGridDateCombineContainer_${currMonth}_${day.date}`,
      classNames: 'mapPopupGridDateCombineContainer'
    })
    UI.add(combineCellContainer, weekDayCell)
    UI.add(combineCellContainer, dateCell)
    UI.add(combineCellContainer, availabilityCellContainer)
    UI.add(combineCellContainer, availabilityCellContainer)
    UI.add(panel, combineCellContainer)
  }

  function createGridForTransientView(panel) {
    let currMonth = null
    let girdDays = getValue(() => uiData.availabilityGrid.days, []);
    if (uiData.baseInfo.slipReservationType == "Seasonal" || uiData.baseInfo.slipReservationType == "Lease") {
      girdDays = getValue(() => uiData.availabilityGridForTransient.days, []);
    }

    girdDays.forEach((day) => {
      const monthInd = day.monthIndicator
      if (monthInd) {
        currMonth = day.month
        const monthCellContainer = Div({ id: `MapPopupGridMonthCellContainer_${currMonth}`, classNames: 'mapPopupGridMonthCellContainer' })
        const monthCell = Div({
          id: `MapPopupGridMonthCell_${currMonth}`,
          classNames: 'month',
          content: currMonth,
          style: null,
          asSpan: true,
          onClick: null,
          role: 'region',
          ariaLabel: 'Month'
        })
        UI.add(monthCellContainer, monthCell)
        UI.add(panel, monthCellContainer)
      } else {
        createGridCell(panel, currMonth, day);
      }
    })

  }

  function createFixedActionButtonsSection(actions, resvNum, slipReservationType) {
    // Fixed actions
    const actionsContainer = Div({ id: 'MapPopupActionsContainer', classNames: 'mapPopupActionsContainer' })
    let fixedActionDisplayCount = 1
    actions.forEach(((action) => {
      if (fixedActionDisplayCount <= DEFAULT_ACTION_DISPLAY_COUNT) {
        const actionButton = Anchor({
          id: action.id,
          classNames: action.style,
          content: action.label,
          url: null,
          onClick: (event) => {
            feature.properties.resvNum = resvNum;
            feature.properties.slipReservationType = slipReservationType;
            closePopups()
            callback(
              getActionObj(ACTION_TYPE.ACTION_ITEM_CLICK, feature.properties, event.target.id)
            )
          }
        })
        UI.add(actionsContainer, actionButton)
        fixedActionDisplayCount += 1
      }
    }))
    // Dynamic actions control button
    let controlButtonContainer = null
    if (actions.length > DEFAULT_ACTION_DISPLAY_COUNT) {
      const controlButton = Anchor({
        id: 'MapPopupDynamicActionsControlButton',
        classNames: 'mapPopupDynamicActionsControlButtonDown',
        content: '',
        url: null,
        onClick: (event) => {
          callback(
            getActionObj(ACTION_TYPE.ACTION_ITEM_CLICK, feature.properties, event.target.id)
          )
        }
      })
      controlButtonContainer = Div({
        id: 'MapPopupDynamicActionsControlButtonContainer',
        classNames: 'mapPopupDynamicActionsControlButtonContainer'
      })
      UI.add(controlButtonContainer, controlButton)
    }
    // Fixed actions and dynamic actions control button container
    const fixedActionsContainer = Div({ id: 'MapPopupFixedActionsContainer', classNames: 'mapSlipPopupFixedActionsContainer' })
    UI.add(fixedActionsContainer, actionsContainer)
    if (!isEmpty(controlButtonContainer)) UI.add(fixedActionsContainer, controlButtonContainer)

    return fixedActionsContainer
  }

  function createDynamicActionButtonsSection(actions, resvNum) {
    const dynamicActionsContainer = Div({ id: 'MapPopupDynamicActionsContainer', classNames: 'mapPopupDynamicActionsContainer' })
    const dynamicActionsWrapContainer = Div({ id: 'MapPopupDynamicActionsWrapContainer', classNames: 'mapPopupDynamicActionsWrapContainer' })
    let action = null
    for (let i = DEFAULT_ACTION_DISPLAY_COUNT; i < actions.length; i += 1) {
      action = actions[i]
      const actionButton = Anchor({
        id: action.id,
        classNames: action.style,
        content: action.label,
        url: null,
        onClick: (event) => {
          feature.properties.resvNum = resvNum;
          feature.properties.slipReservationType = slipReservationType;
          closePopups()
          callback(getActionObj(ACTION_TYPE.ACTION_ITEM_CLICK, feature.properties, event.target.id))
        }
      })
      UI.add(dynamicActionsWrapContainer, actionButton)
    }
    UI.add(dynamicActionsContainer, dynamicActionsWrapContainer)

    return dynamicActionsContainer
  }

  function getClickMainUI() {
    getSiteStatusId();
    const mainContainer = PopupMainContainer({ mainStyle })
    const popupContainer = Div({
      id: 'MapPopupContainer',
      classNames: 'mapPopupContainer'
    });
    if (uiData.baseInfo.slipReservationType == "Seasonal" || uiData.baseInfo.slipReservationType == "Lease") {
      if (uiData.isSharable) {
        createSlipReservationTabs(popupContainer);
        UI.add(popupContainer, createSlipContainer(uiData.baseInfo.slipReservationType, "active"));
        if (!hasSeasonLeaseReservation(uiData.reservation)) {
          UI.add(popupContainer, createSlipContainer("Transient", ""));
        }
      } else {
        UI.add(popupContainer, createSlipContainer(uiData.baseInfo.slipReservationType, "active"));
      }
    } else {
      UI.add(popupContainer, createSlipContainer(uiData.baseInfo.slipReservationType ? uiData.baseInfo.slipReservationType : "Unknown Slip Reservation Type", "active"));
    }
    UI.add(mainContainer, popupContainer);
    return mainContainer
  }

  function hasSeasonLeaseReservation(reservation) {
    if (reservation && reservation.length > 0) {
      return reservation.filter(res => res.resvType == "Seasonal" || res.resvType == "Lease").length > 0;
    }
    return false;
  }

  function createSlipContainer(slipReservationType, active) {
    const slipContainer = Div({
      id: `MapPopupSlipContainer-${slipReservationType}`,
      classNames: `mapPopupSlipContainer ${active}`
    })
    // Create base info section
    UI.add(slipContainer, createBaseInfoSection(slipReservationType));
    // Create attributes section
    const attrSection = createAttributesSection();
    if (!isEmpty(attrSection)) {
      UI.add(slipContainer, attrSection);
    }

    if (uiData.reservation) {

      let reservationsByType = uiData.reservation.filter(res => res.resvType == slipReservationType);
      if (reservationsByType.length > 0) {
        createTabsForReservations(slipContainer, reservationsByType, slipReservationType);
      }
    }
    UI.add(slipContainer, createAvailibilityGridSection(slipReservationType));
    return slipContainer;
  }

  function createSlipReservationTabs(slipContainer) {
    const slipReservationTypeTabs = Div({
      id: 'slipReservationTypeTabs',
      content: '',
      style: null,
      asSpan: false,
      classNames: 'slip-ReservationType-container'
    })
    const seasonLeaseTab = Div({
      id: `season-lease-tab`,
      content: `${uiData.baseInfo.slipReservationType}`,
      style: null,
      asSpan: false,
      classNames: `tablink-slip-reservation-type activeLink`,
      onClick: () => {
        document.getElementById(`MapPopupSlipContainer-Transient`).classList.remove('active');
        if (document.getElementById(`MapPopupSlipContainer-${uiData.baseInfo.slipReservationType}`)) {
          document.getElementById(`MapPopupSlipContainer-${uiData.baseInfo.slipReservationType}`).classList.add('active');
        }
        document.getElementById(`transient-tab`).classList.remove("activeLink");
        // document.getElementsByClassName('tablink-slip')[0].classList.add("activeLink");
        let activatedTabsSeason =  document.getElementsByClassName('tap-Seasonal activeLink');
        if(activatedTabsSeason == 0){
          document.getElementsByClassName('tablink-slip tap-Seasonal')[0].classList.add("activeLink");
        }
        let activatedTabsLease =  document.getElementsByClassName('tap-Lease activeLink');
        if(activatedTabsLease == 0){
          document.getElementsByClassName('tablink-slip tap-Lease')[0].classList.add("activeLink");
        }
        document.getElementById(`season-lease-tab`).classList.add("activeLink");
      }
    });

    const transientTab = Div({
      id: `transient-tab`,
      content: `Transient`,
      style: null,
      asSpan: false,
      classNames: `tablink-slip-reservation-type`,
      onClick: () => {
        let i;
        document.getElementById(`MapPopupSlipContainer-Transient`).classList.add('active');
        if( document.getElementById(`MapPopupSlipContainer-${uiData.baseInfo.slipReservationType}`)){
          document.getElementById(`MapPopupSlipContainer-${uiData.baseInfo.slipReservationType}`).classList.remove('active');
        }
        let tablinks = document.getElementsByClassName("tablink-slip-reservation-type");
        for (i = 0; i < tablinks.length; i++) {
          tablinks[i].classList.remove("activeLink");
        }
        document.getElementById(`transient-tab`).classList.add("activeLink");
        let activatedTabs =  document.getElementsByClassName('tab-Transient activeLink');
        if(activatedTabs == 0){
          document.getElementsByClassName('tablink-slip tab-Transient')[0].classList.add("activeLink");
        }
      }
    })
    UI.add(slipReservationTypeTabs, seasonLeaseTab);
    if (uiData.availabilityGridForTransient) {
      if (uiData.availabilityGridForTransient.days.length > 0 && !hasSeasonLeaseReservation(uiData.reservation)) {
        UI.add(slipReservationTypeTabs, transientTab);
      }
    }
    UI.add(slipContainer, slipReservationTypeTabs);
  }

  function getHoverHead() {
    const containerUI = Div({ id: 'head', classNames: 'head' })
    let title = getValue(() => properties.name, null)
    if (isEmpty(title)) {
      title = Intl.simpleTranslate(`markerIconType_${uiData.head.titleTextFallbackIntlToken}`, uiData.head.titleTextFallbackIntlToken)
      if (!isEmpty(properties.displayLabel) && title === uiData.head.titleTextFallbackIntlToken) {
        title = properties.displayLabel
      }
    }
    if (!isEmpty(title)) {
      const titleUI = Div({ classNames: 'gismaps-popup-title', content: title })
      UI.add(containerUI, titleUI)
    }

    return containerUI
  }

  function getHoverMainUI() {
    const mainContainer = PopupMainContainer({ mainStyle })
    UI.add(mainContainer, getHoverHead())
    return mainContainer
  }

  function getMainUI() {
    if (isHover || uiData.head.featureType === 'amenity') {
      return getHoverMainUI()
    }

    // Get site status id
    const mainContainer = getClickMainUI()

    return mainContainer
  }

  return getMainUI()
}

export default PopupFMSlip
