import Div from '../../base/Div'
import Image from '../../base/Image'
import UI from '../../base/UI'
import {isEmpty} from '../../../../utils/GeneralUtils'

function Icon({
  firstImg = null, secondImg = null, classNames = '', firstImgClassNames = '', secondImgClassNames = '',
  style = ''
}) {
  function getUI() {
    const mainContainer = Div({classNames: `a1mv__icon-container ${classNames}`})

    if (!isEmpty(firstImg)) {
      const fImage = Image({src: firstImg, classNames: `a1mv__icon-first-image ${firstImgClassNames}`, inlineStyle: style })
      UI.add(mainContainer, fImage)
    }

    if (!isEmpty(secondImg)) {
      const sImage = Image({src: secondImg, classNames: `a1mv__icon-second-image ${secondImgClassNames}`})
      UI.add(mainContainer, sImage)
    }

    return mainContainer
  }

  return getUI()
}

export default Icon
