/* eslint-disable no-param-reassign */
/**
 * @file   : GeoMap.js
 * @author : Amarjeet Lamba (amarjeet.lamba@aspiraconnect.com)
 * @date   : 8/19/2020, 11:49:25 AM
 */
/** ****************************************
 *                                         *
 *              FRAMEWORK                  *
 *  (Follow Framework Editing Guidelines)  *
 *          (See Readme Files)             *
 *                                         *
 ***************************************** */

import {MAP_ACTION_BUTTON_ID, MAP_ACTION_ID, MAP_ACTION_TYPE} from '../utils/MapActionsUtils'
import {
  MARKER_TYPE_WITH_CUSTOMIZED_ICON,
  changeMapStyle,
  closePopups,
  createPopup,
  createMapAndDrawData,
  getMapImage,
  getXYForId,
  highlight,
  recenter,
  togglePerspective,
  updateMap
} from './MapboxMapUtils'
import {
  cloneDeepJSON,
  findObjInArray,
  getValue,
  isEmpty,
  isUndefined,
  loadData,
  log
} from '../utils/GeneralUtils'

// import MapActionsUI from './components/overlay/MapActionsUI'
import Config from '../config/Config'
import Intl from '../intl/Intl'
import MapActionsUI from './components/overlay/MapActionsUI'

const PENDING_ACTION_TYPES = {
  SET_DATA: 'setData',
  UPDATE_DATA: 'updateData',
  HIGHLIGHT: 'highlight',
  EXEC_MAP_PLUGIN: 'execMapPlugin'
}

class GeoMap {
  constructor() {
    this.map = null
    this.initComplete = false
    this.isDataSet = false
    this.clientCallback = null
    this.containerId = null
    // this.mapConfig = null
    this.mainConfig = null
    this.mainView = null
    this.massagedData = null
    this.legendData = null
    this.categoryData = null
    this.mapActionsUI = null
    this.busy = false
    this.pendingActions = []
    this.intlObj = null

    this.currentMapData = {
      mapStyleId: null
    }

    Config.get()
  }

  // satelliteStyle: {
  //  "satelliteStyleURL":"",
  //  "satelliteVectorStyleURL":""
  // }

  init(containerId, mapConfig, mainConfig, clientCallback, mainConfigPath, customizedAmenityConfig, satelliteStyle) {
    Config.get().setMapConfig(mapConfig)
    this.containerId = containerId
    // this.mapConfig = mapConfig
    this.clientCallback = clientCallback

    // eslint-disable-next-line no-unneeded-ternary
    let shouldLoadMainConfig = true
    if (!isEmpty(mainConfig)) {
      this.setCustomizedAmenityConfig(mainConfig, customizedAmenityConfig)
      this.setSatelliteStyle(mainConfig, satelliteStyle)
      Config.get().setMainConfig(mainConfig)
      this.mainConfig = mainConfig
      shouldLoadMainConfig = false
    } else if (!isEmpty(this.mainConfig) && Config.get().isSetMainConfig()) { // already set
      shouldLoadMainConfig = false
    }

    // eslint-disable-next-line no-unneeded-ternary
    const shouldLoadInt = isEmpty(this.intlObj) ? true : false

    if (shouldLoadMainConfig || shouldLoadInt) {
      const paths = []
      let loadingMainConfig = false
      let loadingIntl = false
      if (shouldLoadMainConfig && !isEmpty(mainConfigPath)) {
        paths.push(mainConfigPath)
        loadingMainConfig = true
      }
      const intlPath = getValue(() => mapConfig.paths.intlPath, null)
      if (shouldLoadInt && !isEmpty(intlPath)) {
        paths.push(`${intlPath + Config.get().getUIOptionsConfig().intl.locale}.json`)
        loadingIntl = true
      }

      if (paths.length > 0) {
        this.setBusy(true)
        loadData(paths[0], (success, data) => {
          if (success) {
            // log('data loaded!!!!', data)
            if (loadingMainConfig) {
              this.setCustomizedAmenityConfig(data, customizedAmenityConfig)
              this.setSatelliteStyle(data, satelliteStyle)
              Config.get().setMainConfig(data)
              // eslint-disable-next-line prefer-destructuring
              this.mainConfig = data
              if (loadingIntl) {
                loadData(paths[1], (success1, data1) => {
                  if (success1) {
                    this.intlObj = data1
                    Intl.setLocale(this.intlObj)
                    Intl.addQualifier(Config.get().getUIOptionsConfig().intl.qualifier)
                    this.setBusy(false)
                  } else {
                    // TODO - handle error
                  }
                })
              } else {
                this.setBusy(false)
              }
            } else {
              this.intlObj = data
              Intl.setLocale(this.intlObj)
              this.setBusy(false)
            }
          } else {
          // TODO - handle error
          }
        })
      }

      // if (paths.length > 0) {
      //   this.setBusy(true)
      //   loadMultiData(paths, (success, data) => {
      //     if (success) {
      //       let intlCtr = 0
      //       log('data loaded!!!!', data)
      //       if (loadingMainConfig) {
      //         Config.get().setMainConfig(data[0])
      //         // eslint-disable-next-line prefer-destructuring
      //         this.mainConfig = data[0]
      //         intlCtr += 1
      //       }

      //       if (loadingIntl) {
      //         this.intlObj = data[intlCtr]
      //       }
      //       this.setBusy(false)
      //     } else {
      //     // TODO - handle error
      //     }
      //   })
      // }
    }

    // if (!isEmpty(mainConfig)) {
    //   Config.get().setMainConfig(mainConfig)
    //   this.mainConfig = mainConfig
    //   // this.createMapActionsUI(containerId)
    // } else if (!isEmpty(this.mainConfig) && Config.get().isSetMainConfig()) {
    //   // this.createMapActionsUI(containerId)
    // } else if (!isEmpty(mainConfigPath)) {
    //   this.setBusy(true)
    //   loadMultiData([mainConfigPath], (success, data) => {
    //     if (success) {
    //       log('data loaded!!!!', data)
    //       Config.get().setMainConfig(data[0])
    //       this.mainConfig = data[0]
    //       // this.createMapActionsUI(containerId)
    //       this.setBusy(false)
    //     } else {
    //       // TODO - handle error
    //     }
    //   })
    // } else {
    //   // TODO - handle error
    // }
  }

  setSatelliteStyle(mainConfig, satelliteStyle) {
    if (!isEmpty(satelliteStyle)) {
      var tileProvidersSatellite = mainConfig.map.tileProviders.find((item)=> item.id == 'satellite')
      if (!isEmpty(satelliteStyle.satelliteStyleURL)) {
        tileProvidersSatellite.url = satelliteStyle.satelliteStyleURL
      }
      if (!isEmpty(satelliteStyle.satelliteVectorStyleURL)) {
        tileProvidersSatellite.vectorStyle = satelliteStyle.satelliteVectorStyleURL
      }
    }
  }

  setCustomizedAmenityConfig(mainConfig, customizedAmenityConfig) {
    if (!isEmpty(customizedAmenityConfig)) {
      customizedAmenityConfig.amenityCategories.forEach((userObj) => {
        userObj.Name = userObj.name
      })
      mainConfig.category.types = customizedAmenityConfig.amenityCategories
      mainConfig.features.marker.styles = customizedAmenityConfig.amenityTypes.concat(mainConfig.features.marker.styles.filter(
        (ele) => 'textOnly' in ele
      ))
    }
  }

  updateAmenityTypeConfig(customizedAmenityConfig) {
    if (!isEmpty(this.mainConfig)) {
      this.setCustomizedAmenityConfig(this.mainConfig, customizedAmenityConfig)
      Config.get().setMainConfig(this.mainConfig)
    }
  }

  createMapActionsUI() {
    this.mapActionsUI = MapActionsUI({
      mainDivId: this.containerId,
      mapActionCallback: this.mapActionsCallback.bind(this),
      clientCallback: this.clientCallback,
      legendData: this.legendData,
      categoryData: this.categoryData
    })
  }

  preProcessData(data) {
    data.markers.features.forEach((feature) => {
      // avoid the case where some map base icon override A1 icon e.g. volleyball, playground and so on
      // so here add a new property markerIconKey to be used as the icon key that mapbox hold
      feature.properties.markerIconKey = `a_${feature.properties.markerIconType}`

      // if the site icon style id is dots, then here need to reset icon path to be empty
      // so that the icon included in style 'imageIcons' won't be displayed for the style 'dots'
      if (feature.properties.styleId === 'dots' && MARKER_TYPE_WITH_CUSTOMIZED_ICON.indexOf(feature.properties.baseMarkerIconType) === 0) {
        feature.properties.popupIconPath = feature.properties.iconPath
        feature.properties.iconPath = ''
      }
    })
  }

  setData(data) {
    // this.preProcessData(data)
    // Hack - Temp fix for AWO-208376 and AWO-208386
    data = cloneDeepJSON(data)

    if (this.isBusy()) {
      // log('setData - GeoMap - is busy - adding action')
      this.addActionToPending(PENDING_ACTION_TYPES.SET_DATA, data)
    } else {
      this.setBusy(true)
      // log('setData - GeoMap', data)
      this.mainView = null
      this.massageData(data)
      this.massagedData = data

      this.map = createMapAndDrawData(
        this.containerId,
        this.clientCallback,
        this.massagedData,
        this.mainView,
        this.mapActionsCompleteCallback.bind(this)
      )

      this.createMapActionsUI()

      // eslint-disable-next-line max-len
      this.mapActionsUI.refreshUI({updLegendData: this.legendData, updCategoryData: this.categoryData})
    }
  }

  updateData(data, updateMapConfig) {
    // Hack - Temp fix for AWO-208376 and AWO-208386
    data = cloneDeepJSON(data)

    // this.preProcessData(data)
    if (this.isBusy()) {
      // log('updateData - GeoMap - is busy - adding action')
      this.addActionToPending(PENDING_ACTION_TYPES.UPDATE_DATA, data, updateMapConfig)
    } else {
      this.setBusy(true)
      // log('updateData - GeoMap', data)
      this.massageData(data)
      this.massagedData = data

      // eslint-disable-next-line max-len
      updateMap(this.map, this.massagedData, this.clientCallback, updateMapConfig, this.mainView, this.mapActionsCompleteCallback.bind(this))
      closePopups()

      if (!isEmpty(this.mapActionsUI)) {
        // eslint-disable-next-line max-len
        this.mapActionsUI.refreshUI({updLegendData: this.legendData, updCategoryData: this.categoryData})
      }
    }
  }

  highlight(ids) {
    if (this.isBusy()) {
      // log('highlight - GeoMap - is busy - adding action')
      this.addActionToPending(PENDING_ACTION_TYPES.HIGHLIGHT, ids)
    } else {
      const highlightedFeatures = []
      const arrayIds = ids instanceof Array ? ids : [ids]
      arrayIds.forEach((id) => {
        const highlightedFeature = findObjInArray(this.massagedData.markers.features, 'id', id)
        if (!isEmpty(highlightedFeature)) {
          highlightedFeatures.push(highlightedFeature)
        }
      })
      highlight(this.map, highlightedFeatures, this.clientCallback)
    }
    if (!isEmpty(this.mapActionsUI)) {
      // eslint-disable-next-line max-len
      this.mapActionsUI.refreshUI({updLegendData: this.legendData, updCategoryData: this.categoryData})
    }
  }

  getMapImage() {
    if (isEmpty(this.map)) {
      return null
    }

    return getMapImage(this.map)
  }

  getXYForId(id) {
    const feature = findObjInArray(this.massagedData.markers.features, 'id', id)
    if (isEmpty(feature)) {
      return null
    }
    return getXYForId(this.map, feature)
  }

  onCategoryDataChange() {
    const testData = this.filterCategoryData(this.categoryData, this.massagedData)
    const updateSettings = {
      centerMap: {
        type: 'none',
        saveForRecenter: false
      }
    }
    updateMap(
      this.map,
      testData,
      this.clientCallback,
      updateSettings,
      this.mainView,
      this.mapActionsCompleteCallback.bind(this)
    )
    closePopups()
  }

  mapActionsCompleteCallback(done) {
    // log('mapActionsCompleteCallback', done)
    if (done) {
      this.setBusy(false)
    }
  }

  addActionToPending(pendingActionType, data1 = null, data2 = null) {
    this.clearCompletedPendingActions()
    const action = {}
    action.pendingActionType = pendingActionType
    action.data1 = data1
    action.data2 = data2
    action.complete = false

    this.pendingActions.push(action)
  }

  isBusy() {
    return this.busy
  }

  setBusy(isBusy) {
    this.busy = isBusy
    if (!isBusy) {
      this.clearPendingActions()
    }
  }

  clearPendingActions() {
    // log('clearPendingActions...', this.pendingActions)
    if (!isEmpty(this.pendingActions)) {
      this.clearCompletedPendingActions()
      // eslint-disable-next-line no-restricted-syntax
      for (const action of this.pendingActions) {
        if (!action.complete) {
          if (this.isBusy()) {
            break
          } else {
            action.complete = true
            // log('executing pending action...', action)
            switch (action.pendingActionType) {
              case PENDING_ACTION_TYPES.SET_DATA:
                this.setData(action.data1)
                break
              case PENDING_ACTION_TYPES.UPDATE_DATA:
                this.updateData(action.data1, action.data2)
                break
              case PENDING_ACTION_TYPES.HIGHLIGHT:
                this.highlight(action.data1)
                break
              case PENDING_ACTION_TYPES.EXEC_MAP_PLUGIN:
                this.execMapPlugin(action.data1)
                break

              default:
                break
            }
          }
        }
      }
    }
  }

  clearCompletedPendingActions() {
    this.pendingActions = this.pendingActions.filter((action) => action.complete === false)
  }

  // eslint-disable-next-line class-methods-use-this
  addFeatureSizeSettings(feature, sizeConfig = null) {
    let sizeSettings = null
    let styleId = null
    let typeId = 'default'
    if (!isUndefined(feature.properties.styleId)
        && !isEmpty(feature.properties.styleId)) {
      styleId = feature.properties.styleId
    }

    if (!isUndefined(feature.properties.baseMarkerIconType)
        && !isEmpty(feature.properties.baseMarkerIconType)) {
      typeId = feature.properties.baseMarkerIconType

      const styleConfig = findObjInArray(this.mainConfig.markerIcons.style.styles, 'id', feature.properties.styleId)
      let baseMarkerConfig = null
      if (!isUndefined(styleConfig.baseMarkers) && !isEmpty(styleConfig.baseMarkers)) {
        baseMarkerConfig = styleConfig.baseMarkers
      }
      if (!isEmpty(baseMarkerConfig)) {
        const currBaseMarker = findObjInArray(baseMarkerConfig.types, 'id', feature.properties.baseMarkerIconType)
        if (!isEmpty(currBaseMarker) && currBaseMarker.active) {
          feature.properties.sdf = false
        } else {
          feature.properties.sdf = true
        }
      }
    } else {
      feature.properties.sdf = true
    }

    // log('sdf-->', feature.properties.sdf)
    // log('sdf1-->', feature.properties.markerIconType)

    if (!isEmpty(sizeConfig && !isEmpty(styleId))) {
      const styleSettings = findObjInArray(sizeConfig.styles, 'id', styleId)
      if (!isEmpty(styleSettings)) {
        sizeSettings = findObjInArray(styleSettings.settings, 'id', typeId)
      }
    }

    if (isEmpty(sizeSettings)) {
      sizeSettings = Config.get().getMarkerConfig().defaultSizeSettings
    }

    feature.properties.baseMarkerOffset = sizeSettings.baseMarkerOffset

    feature.properties.mainMarkerOffset = sizeSettings.mainMarkerOffset

    feature.properties.textOffset = sizeSettings.textOffset

    feature.properties.textSize = sizeSettings.textSize

    feature.properties.textHaloColor = sizeSettings.textHaloColor

    feature.properties.textHaloWidth = sizeSettings.textHaloWidth

    feature.properties.iconHaloColor = sizeSettings.iconHaloColor

    feature.properties.iconHaloWidth = sizeSettings.iconHaloWidth

    feature.properties.highlightCircleRadius = sizeSettings.highlightCircleRadius

    if (!isUndefined(sizeSettings.sizeTranslation)) {
      feature.properties.iconSize = sizeSettings.sizeTranslation.iconProportion
      feature.properties.baseIconSize = sizeSettings.sizeTranslation.baseIconProportion
    }

    // feature.properties.highlightCircleOffset = sizeSettings.highlightCircleOffset
    if (sizeSettings.id === Config.get().getHighlightConfig().hackHighlightCircleOffsetUseId) {
      // eslint-disable-next-line max-len
      Config.get().getHighlightConfig().hackHighlightCircleOffset = sizeSettings.highlightCircleOffset
    }

    feature.properties.highlightCircleStrokeWidth = sizeSettings.highlightCircleStrokeWidth

    this.addFeatureAccessibilitySizeSettings(feature, sizeSettings)
  }

  // eslint-disable-next-line class-methods-use-this
  addFeatureAccessibilitySizeSettings(feature, sizeSettings) {
    if (!isEmpty(feature.properties.accessibility) && !isEmpty(sizeSettings.cornerMarker)) {
      feature.properties.cornerMarker = {
        ...sizeSettings.cornerMarker,
        bgIconType: 'accessibility_bg',
        iconType: `accessibility_${feature.properties.accessibility}`
      }
      if (isEmpty(feature.properties.cornerMarker.bgColor)) {
        feature.properties.cornerMarker.bgColor = Config.get()
          .getStyleConfig().defaultCornerMarkerColor
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  addCategoryData(mainConfig, categoryData, markerFeature = null, shapeFeature = null) {
    let categoryIds = []
    let type = null
    let displayLabel = null
    if (!isEmpty(markerFeature)) {
      // eslint-disable-next-line max-len
      const alreadyHandledFeatureType = categoryData.featureType[markerFeature.properties.markerIconType]
      if (isEmpty(alreadyHandledFeatureType)) {
        type = markerFeature.properties.markerIconType
        displayLabel = markerFeature.properties.displayLabel
        categoryData.featureType[markerFeature.properties.markerIconType] = true
        const featureStyle = findObjInArray(mainConfig.features.marker.styles, 'type', markerFeature.properties.markerIconType)
        if (!isEmpty(featureStyle)) {
          categoryIds = featureStyle.categoryIds
        } else {
          categoryIds = markerFeature.properties.uiData.slipAvailInfo ? ['slip'] : [Config.get().getUIOptionsConfig().categories.defaultCategoryid]
        }
      }
    }

    if (!isEmpty(shapeFeature)) {
      // eslint-disable-next-line max-len
      const alreadyHandledFeatureType = categoryData.featureType[shapeFeature.properties.shapeStyleId]
      if (isEmpty(alreadyHandledFeatureType)) {
        type = shapeFeature.properties.shapeStyleId

        categoryData.featureType[shapeFeature.properties.shapeStyleId] = true
        const shape = findObjInArray(mainConfig.features.shape.types, 'id', shapeFeature.properties.shapeId)
        if (!isEmpty(shape)) {
          const shapeStyle = findObjInArray(shape.styles, 'id', shapeFeature.properties.shapeStyleId)
          if (!isEmpty(shapeStyle)) {
            categoryIds = shapeStyle.categoryIds
            if (isEmpty(categoryIds)) {
              categoryData.uncategorizedFeatures[shapeFeature.properties.shapeStyleId] = true
            }
          } else {
            categoryIds = []
          }
        }
      }
    }

    if (!isEmpty(categoryIds)) {
      categoryIds.forEach((categoryId) => {
        let categoryObj = findObjInArray(categoryData.categories, 'categoryId', categoryId)
        if (isEmpty(categoryObj)) {
          const category = findObjInArray(mainConfig.category.types, 'id', categoryId)
          categoryObj = {
            categoryId: category.id,
            categoryName: category.name,
            active: true,
            featureTypes: []
          }
          categoryData.categories.push(categoryObj)
        }
        const featureData = {
          type,
          displayLabel,
          active: true
        }
        categoryObj.featureTypes.push(featureData)
      })
    }
  }

  // eslint-disable-next-line class-methods-use-this
  shouldShowByCornerMarker(categoryData, feature) {
    if (isEmpty(categoryData.cornerMarkerCat)) {
      return true
    }
    if (categoryData.cornerMarkerCat.active) {
      if (categoryData.cornerMarkerCat.featureTypes[0].active) {
        return !isEmpty(feature.properties.cornerMarker)
      }
      return true
    }
    return isEmpty(feature.properties.cornerMarker)
  }

  // eslint-disable-next-line class-methods-use-this
  filterCategoryData(categoryData, massagedData) {
    const showTypesMap = {}
    const filteredMassagedData = {}
    filteredMassagedData.location = massagedData.location
    filteredMassagedData.markers = {type: 'FeatureCollection', features: []}
    filteredMassagedData.markersLabels = !isEmpty(massagedData.markersLabels) ? massagedData.markersLabels : {type: 'FeatureCollection', features: []}
    filteredMassagedData.shapesLine = {type: 'FeatureCollection', features: []}
    filteredMassagedData.shapesPolygon = {type: 'FeatureCollection', features: []}

    if (!isEmpty(categoryData) && !isEmpty(categoryData.categories)) {
      categoryData.categories.forEach((category) => {
        if (category.active) {
          category.featureTypes.forEach((featureType) => {
            if (featureType.active && featureType.type) {
              showTypesMap[featureType.type] = true
            }
          })
        }
      })
    }

    // log('showTypesMap', showTypesMap)

    if (!isEmpty(massagedData.markers) && !isEmpty(massagedData.markers.features)) {
      massagedData.markers.features.forEach((feature) => {
        if (!isEmpty(showTypesMap[feature.properties.markerIconType])
            && this.shouldShowByCornerMarker(categoryData, feature)) {
          filteredMassagedData.markers.features.push(feature)
        }
      })
    }

    // if (!isEmpty(massagedData.shapes) && !isEmpty(massagedData.shapes.features)) {
    //   massagedData.shapes.features.forEach((feature) => {
    //     // log('showTypesMap->', feature.properties.shapeStyleId)
    //     if (
    //       !isEmpty(showTypesMap[feature.properties.shapeStyleId])
    //       || categoryData.uncategorizedFeatures[feature.properties.shapeStyleId]
    //     ) {
    //       filteredMassagedData.shapes.features.push(feature)
    //     }
    //   })
    // }

    if (!isEmpty(massagedData.shapesLine) && !isEmpty(massagedData.shapesLine.features)) {
      massagedData.shapesLine.features.forEach((feature) => {
        // log('showTypesMap->', feature.properties.shapeStyleId)
        if (
          !isEmpty(showTypesMap[feature.properties.shapeStyleId])
          || categoryData.uncategorizedFeatures[feature.properties.shapeStyleId]
        ) {
          filteredMassagedData.shapesLine.features.push(feature)
        }
      })
    }

    if (!isEmpty(massagedData.shapesPolygon) && !isEmpty(massagedData.shapesPolygon.features)) {
      massagedData.shapesPolygon.features.forEach((feature) => {
        // log('showTypesMap->', feature.properties.shapeStyleId)
        if (
          !isEmpty(showTypesMap[feature.properties.shapeStyleId])
          || categoryData.uncategorizedFeatures[feature.properties.shapeStyleId]
        ) {
          filteredMassagedData.shapesPolygon.features.push(feature)
        }
      })
    }

    // log('filteredMassagedData-->>', filteredMassagedData)

    return filteredMassagedData
  }

  // eslint-disable-next-line class-methods-use-this
  addDynamicBaseMarkerStatusStyle(feature, statusStyleObj, legendData) {
    let dynamicBaseMarkerStatusStyle = null
    if (!isUndefined(feature.properties.baseMarkerIconType)
        && !isEmpty(feature.properties.baseMarkerIconType)) {
      if (!isEmpty(statusStyleObj) && !isEmpty(statusStyleObj.dynamicBaseMarkerStatusStyle)) {
        // eslint-disable-next-line max-len
        dynamicBaseMarkerStatusStyle = statusStyleObj.dynamicBaseMarkerStatusStyle
      }
      feature.properties.originalBaseMarkerIconType = feature.properties.baseMarkerIconType
    } else {
      feature.properties.originalBaseMarkerIconType = ''
    }

    let currBaseMarker = null
    const styleConfig = findObjInArray(this.mainConfig.markerIcons.style.styles, 'id', feature.properties.styleId)
    let baseMarkerConfig = null
    if (!isEmpty(styleConfig) && !isUndefined(styleConfig.baseMarkers) && !isEmpty(styleConfig.baseMarkers)) {
      baseMarkerConfig = styleConfig.baseMarkers
    }
    if (!isEmpty(baseMarkerConfig)) {
      currBaseMarker = findObjInArray(baseMarkerConfig.types, 'id', feature.properties.baseMarkerIconType)
    }

    const {useDynamicBaseMarkerStatusStyle} = Config.get().getStyleConfig()

    // eslint-disable-next-line max-len
    if (useDynamicBaseMarkerStatusStyle && !isEmpty(currBaseMarker) && currBaseMarker.active && currBaseMarker.supportDynamicStatusStyle && !isEmpty(dynamicBaseMarkerStatusStyle)) {
      feature.properties.baseMarkerIconType += dynamicBaseMarkerStatusStyle
      legendData.dynamicBaseMarkerStatusStyle = dynamicBaseMarkerStatusStyle
    } else {
      legendData.dynamicBaseMarkerStatusStyle = null
    }
    if (!isEmpty(currBaseMarker)) {
      legendData.supportLegendUseBaseMarkerStatusStyle = currBaseMarker.supportLegendUseBaseMarkerStatusStyle
    } else {
      legendData.supportLegendUseBaseMarkerStatusStyle = false
    }
  }

  // eslint-disable-next-line class-methods-use-this
  updateIconColorStyle(feature, statusStyleObj, legendData) {
    // eslint-disable-next-line max-len
    const finalIconColorStyle = this.getIconColorStyleFromPalette(Config.get().getStyleConfig().iconColorPalette)
    const {defaultAttributeTypeOverride} = Config.get().getUIOptionsConfig().legend

    if (!isEmpty(defaultAttributeTypeOverride)) {
      legendData.legendDefaultAttributeType = defaultAttributeTypeOverride
    } else {
      legendData.legendDefaultAttributeType = this.getColorPaletteFromId(Config.get().getStyleConfig().iconColorPalette).legendDefaultAttributeType
    }

    legendData.iconColorStyle = finalIconColorStyle
    legendData.customizedLegendStyle= feature.properties.customizedLegendStyle||'';
    if (!isEmpty(finalIconColorStyle)) {
      if (!isEmpty(statusStyleObj) && !isEmpty(statusStyleObj[`color${finalIconColorStyle}`])) {
        if (!isEmpty(feature.properties.baseMarkerIconType)) {
          feature.properties.baseIconColor = statusStyleObj[`color${finalIconColorStyle}`]
        } else {
          feature.properties.iconColor = statusStyleObj[`color${finalIconColorStyle}`]
        }
      }
    }
    if(feature.properties.bgIconColor)
      feature.properties.baseIconColor = feature.properties.bgIconColor
    if(feature.properties.markerUniqueIdentifier)
      feature.properties.textColor = feature.properties.baseIconColor
    if(feature.properties.customizedTextSize)
      feature.properties.textSize = feature.properties.customizedTextSize
  }

  getColorPaletteFromId(paletteId) {
    let colorPalette = findObjInArray(this.mainConfig.attributeNew.style.iconColorPalettes, 'id', paletteId)

    if (isEmpty(colorPalette)) {
      paletteId = Config.get().getStyleConfig().defaultColorPalette
      colorPalette = findObjInArray(this.mainConfig.attributeNew.style.iconColorPalettes, 'id', paletteId)
    }

    return colorPalette
  }

  getIconColorStyleFromPalette(paletteId) {
    return this.getColorPaletteFromId(paletteId).appender
  }

  massageData(data) {
    this.preProcessData(data)
    this.legendData = null
    let currLegendData = null
    this.categoryData = {categories: [], featureType: {}, uncategorizedFeatures: {}}
    const legendCategoryPropertyId = Config.get().getUIOptionsConfig().legend.categoryPropertyId
    if (!isEmpty(this.mainConfig)) {
      let markersCtr = 0
      const markersIndexesToDelete = []
      data.markersLabels = {type: 'FeatureCollection', features: []}
      data.markers.features.forEach((feature) => {
        this.addCategoryData(this.mainConfig, this.categoryData, feature)
        currLegendData = {}
        if (!isEmpty(legendCategoryPropertyId)) {
          currLegendData.categoryId = feature.properties[legendCategoryPropertyId]
        }
        // log('feature.properties.markerIconType', feature.properties.markerIconType)
        // log('feature.properties.markerIconSizeId', feature.properties.markerIconSizeId)
        currLegendData.markerIconType = feature.properties.markerIconType
        currLegendData.markerIconPath = feature.properties.iconPath
        currLegendData.displayLabel = feature.properties.displayLabel
        currLegendData.markerIconSizeId = feature.properties.markerIconSizeId
        currLegendData.styleId = feature.properties.styleId

        if (!isEmpty(feature.properties.markerIconSizeId)) {
          const size = findObjInArray(this.mainConfig.markerIcons.size.sizes, 'id', feature.properties.markerIconSizeId)

          feature.properties.iconSize = size.sizeTranslation.proportion
          feature.properties.baseIconSize = size.sizeTranslation.proportion
          this.addFeatureSizeSettings(feature, size)
        } else {
          feature.properties.iconSize = 1
          feature.properties.baseIconSize = 1
          this.addFeatureSizeSettings(feature, null)
        }
        // log('feature.properties.iconSize', feature.properties.iconSize)
        currLegendData.iconSize = feature.properties.iconSize

        if (!isUndefined(feature.properties.id)) {
          feature.id = feature.properties.id
        }
        if (!isUndefined(feature.properties.baseMarkerIconType)
        && !isEmpty(feature.properties.baseMarkerIconType)) {
          const styleConfig = findObjInArray(this.mainConfig.markerIcons.style.styles, 'id', feature.properties.styleId)
          let baseMarkerConfig = null
          if (!isUndefined(styleConfig.baseMarkers) && !isEmpty(styleConfig.baseMarkers)) {
            baseMarkerConfig = styleConfig.baseMarkers
          }
          if (!isEmpty(baseMarkerConfig)) {
            const currBaseMarker = findObjInArray(baseMarkerConfig.types, 'id', feature.properties.baseMarkerIconType)
            if (!isEmpty(currBaseMarker) && currBaseMarker.active) {
              // do nothing
            } else {
              feature.properties.baseMarkerIconType = null
            }
          }
        }
        // log('feature.properties.baseMarkerIconType', feature.properties.baseMarkerIconType)
        currLegendData.baseMarkerIconType = feature.properties.baseMarkerIconType

        let statusColorObj = this.getAttributeStyleIdFromAttributeIds(feature.properties)

        if (isEmpty(statusColorObj)) {
          const attributeType = findObjInArray(this.mainConfig.attributeNew.types, 'id', Config.get().getStyleConfig().defaultIconAttributeType)
          if (!isEmpty(attributeType) && !isEmpty(attributeType.color)) {
            statusColorObj = attributeType
          }
        }

        currLegendData.statusColorObj = statusColorObj

        let statusColor = null
        if (!isEmpty(statusColorObj)) {
          statusColor = statusColorObj.color
        }
        if (isEmpty(statusColor)) {
          statusColor = Config.get().getStyleConfig().defaultIconColor
        }
        if (!isUndefined(feature.properties.baseMarkerIconType)
        && !isEmpty(feature.properties.baseMarkerIconType)) {
          feature.properties.baseIconColor = statusColor

          feature.properties.iconColor = Config.get().getStyleConfig().defaultIconColor
        } else {
          feature.properties.baseIconColor = Config.get().getStyleConfig().defaultIconColor

          feature.properties.iconColor = statusColor
        }

        this.updateIconColorStyle(feature, statusColorObj, currLegendData)

        this.addDynamicBaseMarkerStatusStyle(feature, statusColorObj, currLegendData)

        this.addTextAttributesToFeature(feature)
        if (!feature.properties.textOnly) {
          this.addLegendData(currLegendData)
        } else {
          data.markersLabels.features.push(feature)
          markersIndexesToDelete.push(markersCtr)
        }
        markersCtr += 1
      })

      const firstFeatureWithCornerMarker = data.markers.features.find(
        (f) => !isEmpty(f.properties.cornerMarker)
      )
      if (!isEmpty(firstFeatureWithCornerMarker)) {
        this.categoryData.cornerMarkerCat = {
          categoryId: 'cornerMarker',
          categoryName: 'cornerMarker',
          active: true,
          bgColor: Config.get().getStyleConfig().defaultCornerMarkerColor,
          featureTypes: [{type: 'withCornerMarkerOnly', active: false}]
        }
      }

      if (!isEmpty(markersIndexesToDelete)) {
        for (let i = markersIndexesToDelete.length - 1; i >= 0; i -= 1) {
          data.markers.features.splice(markersIndexesToDelete[i], 1)
        }
      }

      if (!isEmpty(data.shapes)) {
        const indexesToDelete = []

        data.shapesLine = {type: 'FeatureCollection', features: []}

        data.shapesPolygon = {type: 'FeatureCollection', features: []}

        let shapesCtr = 0
        data.shapes.features.forEach((feature) => {
          // log('feature.properties.name', feature.properties.name)
          this.addCategoryData(this.mainConfig, this.categoryData, null, feature)
          const shapeConfig = findObjInArray(this.mainConfig.features.shape.types, 'id', feature.properties.shapeId)

          if (!isEmpty(shapeConfig)) {
            feature.properties.interactive = shapeConfig.interactive
            if (!shapeConfig.displayed) {
              if (feature.properties.shapeStyleId === 'mainView') {
                this.mainView = feature
              }
              // const index = data.shapes.features.indexOf(feature)
              // if (index > -1) {
              //   data.shapes.features.splice(index, 1)
              // }
              indexesToDelete.push(shapesCtr)
            } else {
              const shapeStyle = findObjInArray(shapeConfig.styles, 'id', feature.properties.shapeStyleId)
              if (!isEmpty(shapeStyle)) {
                if (!isEmpty(shapeStyle.def)) {
                  Object.assign(feature.properties, shapeStyle.def)
                }
                if (!isEmpty(feature.properties.dashArray)) {
                  const parts = feature.properties.dashArray[0].split(' ')
                  // eslint-disable-next-line radix
                  feature.properties.dashArray = [parseInt(parts[0]), parseInt(parts[1])]
                }

                if (!isEmpty(shapeStyle.defHighlight)) {
                  if (!isEmpty(shapeStyle.defHighlight.color)) {
                    feature.properties.highlightColor = shapeStyle.defHighlight.color
                  }
                  if (!isEmpty(shapeStyle.defHighlight.opacity)) {
                    feature.properties.highlightOpacity = shapeStyle.defHighlight.opacity
                  }
                }
                // feature.properties.styleDef = shapeStyle.def
                // feature.properties.styleDefHighlight = shapeStyle.defHighlight
              }

              if (shapeConfig.shapeType === 'lineString' || (!isEmpty(shapeStyle) && shapeStyle.renderAsLineString)) {
                data.shapesLine.features.push(feature)
              } else if (shapeConfig.shapeType === 'polygon') {
                data.shapesPolygon.features.push(feature)
              }
            }
          }
          shapesCtr += 1
          feature.id = shapesCtr
        })

        if (!isEmpty(indexesToDelete)) {
          indexesToDelete.forEach((indexToDelete) => {
            data.shapes.features.splice(indexToDelete, 1)
          })
        }

        delete data.shapes
      }
    }
    log('massagedData', data)
    log('legendData', this.legendData)
    log('categoryData', this.categoryData)
  }

  addLegendData(currLegendData = {}) {
    const defaultCategoryId = 'other'
    if (!isEmpty(currLegendData)) {
      if (isEmpty(this.legendData)) {
        this.legendData = {}
        this.legendData.categories = []
      }

      if (isEmpty(currLegendData.categoryId)) {
        currLegendData.categoryId = defaultCategoryId
      }
      let currCategory = findObjInArray(this.legendData.categories, 'id', currLegendData.categoryId)

      if (isEmpty(currCategory)) {
        currCategory = {
          id: currLegendData.categoryId,
          name: currLegendData.categoryName,
          data: []
        }
        this.legendData.categories.push(currCategory)
      }

      currCategory.data.push(currLegendData)
    }
  }

  // TODO - Use maps or something in this function to reduce the number of
  // searches in arrays and objects.
  // eslint-disable-next-line class-methods-use-this
  getAttributeStyleIdFromAttributeIds(properties) {
    let style = ''
    let priority = 0
    if (!isEmpty(properties)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(properties)) {
        const attrConfObj = findObjInArray(this.mainConfig.attributeNew.config.types, 'id', key)
        if (!isEmpty(attrConfObj)) {
          if (attrConfObj.uiDisplayTypeIds.indexOf('iconStyle') !== -1) {
            if (!isEmpty(value) && (priority === 0 || attrConfObj.stylePriority < priority)) {
              style = value
              priority = attrConfObj.stylePriority
            }
          }
        }
      }
    }
    const attributeType = findObjInArray(this.mainConfig.attributeNew.types, 'id', style)
    if (!isEmpty(attributeType) && !isEmpty(attributeType.color)) {
      return attributeType
    }
    return null
  }

  addTextAttributesToFeature(feature) {
    const featureStyle = findObjInArray(this.mainConfig.features.marker.styles, 'type', feature.properties.markerIconType)
    let isTextOnly = false
    if (!isEmpty(featureStyle) && !isEmpty(featureStyle.textOnly) && featureStyle.textOnly) {
      isTextOnly = true
    }
    feature.properties.textOnly = isTextOnly
    if (isTextOnly) {
      feature.properties.textOffset = featureStyle.textOffset

      feature.properties.textSize = featureStyle.textSize

      feature.properties.textColor = featureStyle.textColor

      feature.properties.textHaloColor = featureStyle.textHaloColor

      feature.properties.textHaloWidth = featureStyle.textHaloWidth
    }
  }

  // eslint-disable-next-line class-methods-use-this
  mapActionsCallback(mapActionObj) {
    if (mapActionObj.actionType === MAP_ACTION_TYPE.ITEM_CLICK
      && mapActionObj.id === MAP_ACTION_ID.PERSPECTIVE_BUTTON) {
      togglePerspective(this.map)
    } else if (mapActionObj.actionType === MAP_ACTION_TYPE.ITEM_CLICK
      && mapActionObj.id === MAP_ACTION_ID.MAP_STYLE_BUTTON) {
      // avoid mutiple map style swith request in a short time, otherwise the layer will disappear
      if (this.isBusy()) {
        return
      }
      this.setBusy(true)
      let currentMapStyleId = this.currentMapData.mapStyleId
      if (isEmpty(currentMapStyleId)) {
        currentMapStyleId = Config.get().getDefaultMapStyleId()
      }

      // log('currentMapStyleId', currentMapStyleId)

      const nextMapStyle = Config.get().getNextMapStyle(currentMapStyleId)
      // log('currentMapStyleId4', nextMapStyle.id)
      this.currentMapData.mapStyleId = nextMapStyle.id
      changeMapStyle(this.map, nextMapStyle, this.massagedData, (styleId) => {
        const nextAvailMapStyle = Config.get().getNextMapStyle(styleId)
        this.mapActionsUI.updateMapStyleSwitchButton(nextAvailMapStyle)
        this.setBusy(false)
      })
      // drawData(this.map, this.massagedData, this.clientCallback, this.mapConfig)
      // eslint-disable-next-line max-len
      // updateMap(this.map, this.massagedData, this.clientCallback, {}, this.mainView)
    } else if (mapActionObj.actionType === MAP_ACTION_TYPE.ITEM_CLICK
      && mapActionObj.id === MAP_ACTION_ID.RECENTER_BUTTON) {
      recenter(this.map, this.massagedData)
    } else if (mapActionObj.actionType === MAP_ACTION_TYPE.DATA_CHANGE
      && mapActionObj.id === MAP_ACTION_ID.CATEGORY_DATA) {
      this.onCategoryDataChange()
    }
  }

  execMapPlugin(func) {
    if (isEmpty(this.map)) {
      this.addActionToPending(PENDING_ACTION_TYPES.EXEC_MAP_PLUGIN, func)
    } else {
      func(this.map)
    }
  }

   createPopupByFeature(feature) {
    createPopup(this.map, feature, this.clientCallback,false,false);
   }

   closeAllPopups(){
    closePopups();
   }

}

// Singleton Instance.
let instance = null

// eslint-disable-next-line one-var
const getInstance = function getInstanceFn() {
  if (!instance) {
    instance = new GeoMap()
  }

  return instance
}

// Exposed methods
export default {get: getInstance}
