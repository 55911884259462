/* eslint-disable no-useless-escape */
import {isEmpty, isUndefined} from '../../../utils/GeneralUtils'

const UI = {
  add(parent, child) {
    parent.appendChild(child)
  },

  remove(parent, child) {
    parent.removeChild(child)
  },

  removeSelf(el) {
    const domEl = this.getElement(el)
    if (this.isElement(domEl)) {
      domEl.parentNode.removeChild(domEl)
    }
  },

  replace(oldEl, newEl) {
    const domEl = this.getElement(oldEl)
    if (this.isElement(domEl)) {
      domEl.parentNode.replaceChild(newEl, oldEl)
    }
  },

  setClasses(el, classNames) {
    const domEl = this.getElement(el)
    if (this.isElement(domEl)) {
      domEl.className = classNames
    }
  },

  addClass(el, className) {
    const domEl = this.getElement(el)
    if (this.isElement(domEl)) {
      domEl.className += ` ${className}`
    }
  },

  removeClass(el, className) {
    const domEl = this.getElement(el)
    if (this.isElement(domEl)) {
      // const regex = new RegExp(`(?:^|s)${className}(?!S)`, 'g')

      const regex = new RegExp(`\\b${className}\\b`, 'g')
      domEl.className = domEl.className.replace(regex, '')
    }
  },

  escapeRegExp(stringToGoIntoTheRegex) {
    return stringToGoIntoTheRegex.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
  },

  // TODO - regex not working
  hasClass(el, className) {
    let hasCls = false
    const domEl = this.getElement(el)
    if (this.isElement(domEl)) {
      const regex = new RegExp(`\\b${className}\\b`, 'g')
      if (domEl.className.match(regex)) {
        hasCls = true
      }
    }

    return hasCls
  },

  getElement(el) {
    if (!this.isElement(el)) {
      return null
    }
    const domEl = document.getElementById(el.id)

    return domEl
  },

  isElement(domEl) {
    if (!isUndefined(domEl) && !isEmpty(domEl)) {
      return true
    }

    return false
  }
}

export default UI
