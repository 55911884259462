/**
 * @file   : Config.js
 * @author : Amarjeet Lamba (amarjeet.lamba@aspiraconnect.com)
 * @date   : 8/19/2020, 11:49:25 AM
 */
/** ****************************************
 *                                         *
 *              FRAMEWORK                  *
 *  (Follow Framework Editing Guidelines)  *
 *          (See Readme Files)             *
 *                                         *
 ***************************************** */

import BaseConfig from './base-config.json'
import {
  findObjInArray,
  getValue,
  log,
  isEmpty,
  mergeDeep,
  isUndefined
} from '../utils/GeneralUtils'

class Config {
  constructor() {
    this.mapConfig = BaseConfig
    this.mainConfig = null
    this.mapConfigSet = false
    this.mainConfigSet = false
    log('constructor - mapConfig', this.mapConfig)
  }

  setMainConfig(mainConfig) {
    this.mainConfig = mainConfig
    this.mainConfigSet = true
  }

  setMapConfig(mapConfig) {
    // this.mapConfig = {
    //   ...this.mapConfig,
    //   ...mapConfig
    // }

    // this.mapConfig = Object.assign(this.mapConfig, mapConfig)
    // eslint-disable-next-line no-restricted-syntax
    // for (const key of Object.keys(mapConfig)) {
    //   if (key in this.mapConfig) { // or obj1.hasOwnProperty(key)
    //     this.mapConfig[key] = mapConfig[key]
    //   }
    // }

    this.mapConfig = mergeDeep(this.mapConfig, mapConfig)
    this.mapConfigSet = true

    // log('updateMapConfig', this.mapConfig)
  }

  updateMapConfig(mapConfig) {
    if (this.mapConfigSet) {
      this.mapConfig = mergeDeep(this.mapConfig, mapConfig)
    }
    // log('updateMapConfig', this.mapConfig)
  }
  
  isColorBlindSupport(){
    return this.mapConfig.isColorBlindSupport;
  }

  isSetMainConfig() {
    return this.mainConfigSet
  }

  isSetMapConfig() {
    return this.mapConfigSet
  }

  /* **********Main MapConfigs************* */
  getProviderConfig() {
    return this.mapConfig.providerConfig
  }

  getMapTypeConfig() {
    return this.mapConfig.mapType
  }

  getZoomConfig() {
    return this.mapConfig.zoom
  }

  getStyleConfig() {
    return this.mapConfig.style
  }

  getCenterMapConfig() {
    if (isUndefined(this.mapConfig.centerMap) || isEmpty(this.mapConfig.centerMap)) {
      return null
    }
    return this.mapConfig.centerMap
  }

  getPathsConfig() {
    if (isUndefined(this.mapConfig.paths) || isEmpty(this.mapConfig.paths)) {
      return null
    }
    return this.mapConfig.paths
  }

  getFeatureConfig() {
    return this.mapConfig.feature
  }

  getGroupConfig() {
    return this.mapConfig.group
  }

  getMarkerConfig() {
    return this.mapConfig.marker
  }

  getHighlightConfig() {
    return this.mapConfig.highlight
  }

  getPerspectiveConfig() {
    return this.mapConfig.perspective
  }

  getPopupConfig() {
    return this.mapConfig.popup
  }

  getUIOptionsConfig() {
    return this.mapConfig.uiOptions
  }

  /* **********Low Level Config Options************* */
  getDefaultMapStyleId() {
    let defaultStyleId = getValue(() => this.mapConfig.mapType.defaultTypeId, null)
    if (isEmpty(defaultStyleId)) {
      defaultStyleId = this.mainConfig.map.defaultTileProviderId
    }
    return defaultStyleId
  }

  getMapStyle(selectedStyleId) {
    const tileProvider = findObjInArray(this.mainConfig.map.tileProviders, 'id', selectedStyleId)

    return tileProvider
  }

  getNextMapStyle(currentMapStyleId) {
    const mapStyles = this.getMapStyles()
    let nextMapStyle = null
    if (!isEmpty(mapStyles) && mapStyles.length > 1) {
      for (let index = 0; index < mapStyles.length; index += 1) {
        const ms = mapStyles[index]
        if (ms.id === currentMapStyleId) {
          // log('currentMapStyleId1', index)
          if (index + 1 !== mapStyles.length) {
            // log('currentMapStyleId2', index)
            nextMapStyle = mapStyles[index + 1]
          } else {
            // log('currentMapStyleId3', index)
            // eslint-disable-next-line prefer-destructuring
            nextMapStyle = mapStyles[0]
          }
          break
        }
      }
    }
    return nextMapStyle
  }

  getMapStyles() {
    return this.mainConfig.map.tileProviders
  }

  getMainStyle() {
    return this.mapConfig.style.main
  }

  shouldCluster() {
    return this.mapConfig.group.cluster
  }

  allowOverlap() {
    return this.mapConfig.group.allowOverlap 
  }

  allowOverlapText() {
    return this.mapConfig.group.allowOverlapText
  }

  shouldFilterClusterCount() {
    return this.mapConfig.group.filterClusterCount
  }

  getHighlightStyle() {
    return this.mapConfig.highlight.style
  }

  getClusterDrawSetting(settingId, clusterCountId) {
    return this.mapConfig.group.clusterDrawSettings[clusterCountId][settingId]
  }

  // updateMapConfig(userConfig) {
  //   this.mapConfig = {
  //     ...this.mapConfig,
  //     ...userConfig
  //   }
  //   log('updateMapConfig', this.mapConfig)
  // }
}

// Singleton Instance.
let instance = null

// eslint-disable-next-line one-var
const getInstance = function getInstanceFn() {
  if (!instance) {
    instance = new Config()
  }

  return instance
}

// Exposed methods
export default {get: getInstance}
