import {ACTION_TYPE, getActionObj} from '../../../../utils/ActionsUtils'
import {getValue, isEmpty} from '../../../../utils/GeneralUtils'

import Div from '../../base/Div'
import FlexLayout from '../../base/layout/FlexLayout'
import Image from '../../base/Image'
import PopupActionButton from '../PopupActionButton'
import UI from '../../base/UI'

function getActionButton(uiData = null, feature = null, callback = null) {
  const containerUI = Div({classNames: 'action-buttons'})

  if (!isEmpty(uiData.cta)) {
    const action = uiData.cta
    const button = PopupActionButton({
      id: action.id,
      classNames: action.style,
      label: action.label,
      disabled: action.disabled,
      onClick: (event) => {
        callback(getActionObj(ACTION_TYPE.ACTION_ITEM_CLICK, feature.properties, event.target.id))
      }
    })
    UI.add(containerUI, button)
  }

  return containerUI
}

function PopupSections(uiData = null, feature = null, callback = null) {
  function getCTAUI() {
    const btnContainerUI = Div({classNames: 'btn cell'})
    if (!isEmpty(uiData.itemAvailabilityStatus)) {
      const avstatus = Div({classNames: 'avstatus', content: uiData.itemAvailabilityStatus})
      UI.add(btnContainerUI, avstatus)
    }
    UI.add(btnContainerUI, getActionButton(uiData, feature, callback))
    return btnContainerUI
  }
  function getTabsUI() {
    const head = getValue(() => uiData.head, null)
    if (!isEmpty(head)) {
      const hasAlert = !isEmpty(head.mapsitealerttab)
      const tabs = []
      if (!isEmpty(head.mapsitecontenttab)) {
        const tabClass = hasAlert ? 'sitedetailstab selected' : 'sitedetailstitle'
        const firstTab = Div({
          id: 'sitedetailstab',
          classNames: tabClass,
          content: head.mapsitecontenttab,
          onClick: () => {
            callback(
              getActionObj(ACTION_TYPE.FEATURE_CLICK, 'sitedetailstab', 'mappoptabclick', false)
            )
          }
        })
        tabs.push(firstTab)
      }
      if (hasAlert) {
        const secondTab = Div({
          id: 'alertstab',
          classNames: 'sitealertstab',
          content: head.mapsitealerttab,
          onClick: () => {
            callback(
              getActionObj(ACTION_TYPE.FEATURE_CLICK, 'alertstab', 'mappoptabclick', false)
            )
          }
        })
        tabs.push(secondTab)
      }
      return FlexLayout({content: tabs})
    }
    return Div({})
  }
  function getHeadUI() {
    const containerUI = Div({classNames: 'head'})
    const head = getValue(() => uiData.head, null)

    if (!isEmpty(head)) {
      const accessible = head.accessible === 'true'
      const siteNameContainerUI = Div({classNames: 'site_name_container'})
      if (accessible) {
        const accessibleUI = Image({
          classNames: 'site_accessible', src: '/gis-maps/assets/icons/dots/handicapAccess.png', alt: 'Accessible', title: 'Accessible'
        })
        UI.add(siteNameContainerUI, accessibleUI)
      }
      const siteNameUI = Div({classNames: 'site_name', content: head.siteName})
      UI.add(siteNameContainerUI, siteNameUI)
      UI.add(containerUI, siteNameContainerUI)
      if (!isEmpty(head.seasonalsitemsg)) {
        const seasonalSiteMsgUI = Div({classNames: 'seasonalsitemsg', content: head.seasonalsitemsg})
        UI.add(containerUI, seasonalSiteMsgUI)
      }
      if (!isEmpty(head.areadesclong)) {
        const longDescrUI = Div({classNames: 'description long', content: head.areadesclong})
        UI.add(containerUI, longDescrUI)
      }
    }
    return containerUI
  }
  function getSectionsUI() {
    const containerUI = Div({classNames: 'section'})

    const sections = getValue(() => uiData.sections, null)

    if (!isEmpty(sections)) {
      sections.forEach((section) => {
        const attributes = getValue(() => section.attributes, null)
        if (!isEmpty(attributes)) {
          const attrCol = Div({classNames: 'attributes'})
          attributes.forEach((attribute) => {
            const attributeUI = Div({})
            if (isEmpty(attribute.alttxt)) {
              UI.add(attributeUI, Div({content: `<span>${attribute.title}</span> <strong>${attribute.value}</strong>`}))
            } else {
              const clickableLink = Div({
                id: attribute.id,
                content: `<span class='clickableStyle' alt='${attribute.alttxt}'>${attribute.title}</span> <strong>${attribute.value}</strong>`,
                onClick: () => {
                  callback(
                    getActionObj(ACTION_TYPE.FEATURE_CLICK, attribute, 'clickableStyle')
                  )
                }
              })
              UI.add(attributeUI, clickableLink)
            }
            UI.add(attrCol, attributeUI)
          })
          UI.add(containerUI, attrCol)
        }
      })
    }

    return containerUI
  }
  function getAlertUI() {
    const head = getValue(() => uiData.head, null)
    if (!isEmpty(head)) {
      if (!isEmpty(head.sitealerts)) {
        const containerUI = Div({classNames: 'alertcontent'})
        head.sitealerts.forEach((alert) => {
          const siteAlertUI = Div({classNames: 'sitealertrow', content: alert})
          UI.add(containerUI, siteAlertUI)
        })
        return containerUI
      }
    }
    return null
  }
  function getMainUI() {
    const mainContainer = Div({})
    const tabsUI = getTabsUI()
    UI.add(mainContainer, tabsUI)
    const contentContainer = Div({classNames: 'popupcontent'})
    UI.add(mainContainer, contentContainer)
    const headUI = getHeadUI()
    if (!isEmpty(headUI)) {
      UI.add(contentContainer, headUI)
    }
    const sectionsUI = getSectionsUI()
    if (!isEmpty(sectionsUI)) {
      UI.add(contentContainer, sectionsUI)
    }
    const alertUI = getAlertUI()
    if (!isEmpty(alertUI)) {
      UI.add(mainContainer, alertUI)
    }
    const ctaUI = getCTAUI()
    if (!isEmpty(ctaUI)) {
      UI.add(mainContainer, ctaUI)
    }

    return mainContainer
  }

  return getMainUI()
}

export default PopupSections
