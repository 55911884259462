import {isEmpty} from '../../../utils/GeneralUtils'
import Div from '../base/Div'
import {CLASS_POPUP_PRE} from '../../../uiBuilders/UIUtil'

function PopupMainContainer({id = '', mainStyle = ''}) {
  function getMainUI() {
    const separator = isEmpty(mainStyle) ? '' : '_'
    const mainContainer = Div({id, classNames: `${CLASS_POPUP_PRE}${mainStyle}${separator}main-content`})

    return mainContainer
  }

  return getMainUI()
}

export default PopupMainContainer
