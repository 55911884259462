import {getDiv, addAttribute} from '../../../uiBuilders/UIUtil'
import {isEmpty} from '../../../utils/GeneralUtils'

function Div({
  id = '', classNames = '', content = '', style = '', asSpan = false, onClick = null, role = null, ariaLabel = null
}) {
  function getUI() {
    const div = getDiv(id, classNames, content, style, asSpan)

    addAttribute('role', role, div)
    addAttribute('aria-label', ariaLabel, div)

    if (!isEmpty(onClick)) {
      div.addEventListener('click', (event) => {
        onClick(event)
      })
    }

    return div
  }

  return getUI()
}

export default Div
