import {getInput} from '../../../uiBuilders/UIUtil'
import {isEmpty} from '../../../utils/GeneralUtils'

function Checkbox({
  id = '', classNames = '', label = '', disabled = false, checked = null, onChange = null
}) {
  function getUI() {
    const checkbox = getInput('checkbox', id, classNames, label, disabled, checked === true ? true : null)

    if (!isEmpty(onChange)) {
      checkbox.addEventListener('change', (event) => {
        onChange(event)
      })
    }

    return checkbox
  }

  return getUI()
}

export default Checkbox
