/* eslint-disable no-param-reassign */
import Checkbox from '../../base/Checkbox'
import Div from '../../base/Div'
import Header from '../../base/text/Header'
import Image from '../../base/Image'
import Intl from '../../../../intl/Intl'
import Switch from '../../base/Switch'
import UI from '../../base/UI'
import {isEmpty} from '../../../../utils/GeneralUtils'

function Category({category = null, onCategorySelectionChange = null}) {
  let mainContainer
  let contentUI

  function getCategoryFeatureTypeUI(featureType) {
    const container = Div({classNames: 'a1mv__category-feature'})
    const featureText = Div({
      asSpan: true,
      classNames: 'a1mv__category-selector-text',
      content: Intl.simpleTranslate(`markerIconType_${featureType.type}`, !isEmpty(featureType.displayLabel) ? featureType.displayLabel : featureType.type)
    })
    const featureCheckbox = Checkbox({
      checked: featureType.active,
      onChange: (event) => {
        if (event.target.checked) {
          featureType.active = true
        } else {
          featureType.active = false
        }
        onCategorySelectionChange(featureType, category)
      }
    })

    UI.add(container, featureCheckbox)
    UI.add(container, featureText)

    return container
  }

  function getCategoryContentUI() {
    const container = Div({id: `category-item-${category.categoryId}`, classNames: 'a1mv__category-content'})
    if (!isEmpty(category.featureTypes) && category.active) {
      const sortedFeatureTypes = category.featureTypes.sort((ft1, ft2) => {
        const ft1Text = Intl.simpleTranslate(`markerIconType_${ft1.type}`, !isEmpty(ft1.displayLabel) ? ft1.displayLabel : ft1.type)
        const ft2Text = Intl.simpleTranslate(`markerIconType_${ft2.type}`, !isEmpty(ft2.displayLabel) ? ft2.displayLabel : ft2.type)
        return ft1Text.localeCompare(ft2Text)
      })
      sortedFeatureTypes.forEach((featureType) => {
        UI.add(container, getCategoryFeatureTypeUI(featureType))
      })
    }

    return container
  }

  function getCategoryHeaderUI() {
    const container = Div({classNames: 'a1mv__category-header'})
    // const headerText = Div({asSpan: true, classNames: 'header-l1', content: Intl.simpleTranslate(`category_${category.categoryId}`, category.categoryId)})
    const headerContent = Div({})
    const headerIcon = Image({classNames: 'a1mv__category_header-icon', src: `/gis-maps/assets/icons/categories/cat_icon_${category.categoryId}.png`})
    const headerText = Header({asSpan: true, text: Intl.simpleTranslate(`category_${category.categoryId}`, category.categoryId)})
    UI.add(headerContent, headerIcon)
    UI.add(headerContent, headerText)
    const categorySwitch = Switch({
      checked: category.active,
      onChange: (event) => {
        UI.removeSelf(contentUI)
        contentUI = null
        if (event.target.checked) {
          category.active = true
          contentUI = getCategoryContentUI()
          UI.add(mainContainer, contentUI)
        } else {
          category.active = false
        }
        onCategorySelectionChange(null)
      }
    })
    UI.add(container, headerContent)
    UI.add(container, categorySwitch)

    return container
  }

  function createCategoryUI() {
    mainContainer = Div({classNames: 'a1mv__category-container'})
    UI.add(mainContainer, getCategoryHeaderUI())
    contentUI = getCategoryContentUI()
    UI.add(mainContainer, contentUI)

    return mainContainer
  }

  if (isEmpty(category)) {
    return null
  }

  return createCategoryUI()
}

export default Category
